const formatDateForShift = (dateString, isLocalTime) => {
  let date = new Date(dateString);
  if (isLocalTime) {
    date = new Date(dateString)
    date.setHours(date.getHours() + 7)
  }
  const options = { day: 'numeric', month: 'long', year: 'numeric'};

  let formattedDate = new Intl.DateTimeFormat('ru-RU', options).format(date);
  const monthMap = {
      'января': 'Янв',
      'февраля': 'Фев',
      'марта': 'Мар',
      'апреля': 'Апр',
      'мая': 'Май',
      'июня': 'Июн',
      'июля': 'Июл',
      'августа': 'Авг',
      'сентября': 'Сен',
      'октября': 'Окт',
      'ноября': 'Ноя',
      'декабря': 'Дек',
  };

  for (const [full, short] of Object.entries(monthMap)) {
      formattedDate = formattedDate.replace(full, short);
  }
  return formattedDate;
}
export default formatDateForShift;
