import { useState, useRef, useCallback } from "react"
import { CloseBtn, ExpandBtn } from "../../../widgets/buttons"
import { createPortal } from "react-dom"
import { DATE_FILTER, NOVELTY_FILTER } from "../model/shift-table-keywords"
import { useEscapeKey } from "../../../shared"
import "./_settings-modal.scss"

const SettingsModal = (props) => {
  const {
    loadingSettings,
    applyLoadingSettings,
    onClose
  } = props

  const [pageSizeListOpen, setPageSizeListOpen] = useState(false)
  const [chosenIsLocalTime, setChosenIsLocalTime] = useState(loadingSettings.isLocalTime)
  const [chosenPageSize, setChosenPageSize] = useState(loadingSettings.pageSize)
  const [chosenDateFilter, setChosenDateFilter] = useState(loadingSettings.dateFilter)
  const [chosenNoveltyFilter, setChosenNoveltyFilter] = useState(loadingSettings.noveltyFilter)
  const pageSizeRef = useRef(null)

  const root = document.querySelector('.body-wrapper')

  const togglePageSizeList = () => {
    setPageSizeListOpen((p) => !p)
  }

  const onSubmit = () => {
    applyLoadingSettings(
      chosenIsLocalTime,
      chosenDateFilter,
      chosenNoveltyFilter,
      chosenPageSize
    )

    onClose()
  }

  useEscapeKey(useCallback(() => {
    onClose()
  }, [onClose]))

  return (
    <>
    {createPortal(
      <div className="settings-modal-container">
        <div className="settings-modal modal-container__wrapper">
          <CloseBtn
            style={{ position: "absolute", top: "1.4rem", right: "1.4rem" }}
            blackOrWhite="white"
            onClick={onClose}
          />

          <div className="field">
            <h3 className="field__label">Отображать по</h3>
            <div className="switcher-between-pages">
              <button
                onClick={() => setChosenDateFilter(DATE_FILTER.startDate)}
                className={`switcher-between-pages__button ${
                  chosenDateFilter === DATE_FILTER.startDate &&
                  "switcher-between-pages__button--current"
                }`}
              >
                <span>Дате начала</span>
              </button>

              <button
                onClick={() => setChosenDateFilter(DATE_FILTER.loadingDate)}
                type="button"
                className={`switcher-between-pages__button ${
                  chosenDateFilter === DATE_FILTER.loadingDate &&
                  "switcher-between-pages__button--current"
                }`}
              >
                <span>Дате загрузки</span>
              </button>
            </div>
          </div>

          <div className="field">
            <h3 className="field__label">Отображать</h3>
            <div className="switcher-between-pages">
              <button
                onClick={() =>
                  setChosenNoveltyFilter(NOVELTY_FILTER.newestFirst)
                }
                className={`switcher-between-pages__button ${
                  chosenNoveltyFilter === NOVELTY_FILTER.newestFirst &&
                  "switcher-between-pages__button--current"
                }`}
              >
                <span>Сначала новые</span>
              </button>

              <button
                onClick={() =>
                  setChosenNoveltyFilter(NOVELTY_FILTER.oldestFirst)
                }
                type="button"
                className={`switcher-between-pages__button ${
                  chosenNoveltyFilter === NOVELTY_FILTER.oldestFirst &&
                  "switcher-between-pages__button--current"
                }`}
              >
                <span>Сначала старые</span>
              </button>
            </div>
          </div>

          <div className="field">
            <h3 className="field__label">Время</h3>
            <div className="switcher-between-pages change-time-zone">
              <button
                onClick={() => setChosenIsLocalTime(true)}
                className={`switcher-between-pages__button ${
                  chosenIsLocalTime && "switcher-between-pages__button--current"
                }`}
              >
                <span>Местное время</span>
              </button>

              <button
                onClick={() => setChosenIsLocalTime(false)}
                type="button"
                className={`switcher-between-pages__button ${
                  !chosenIsLocalTime &&
                  "switcher-between-pages__button--current"
                }`}
              >
                <span>Время по мск</span>
              </button>
            </div>
          </div>

          <div className="field settings-modal__page-size-editor">
            <h3 className="field__label">Количество отображаемых смен:</h3>
            <div className="page-size-editor">
              <button
                className="page-size-editor__button"
                type="button"
              >
                {chosenPageSize}
                {pageSizeListOpen && (
                  <>
                    <ul className="page-size-list" ref={pageSizeRef}>
                    <li className={`page-size-item ${chosenPageSize === 1000 && 'current-page-size' }`}>
                        <button
                          onClick={() => {
                            togglePageSizeList()
                            setChosenPageSize(1000)
                          }}
                          type="button"
                        >
                          1000
                        </button>
                      </li>
                    <li className={`page-size-item ${chosenPageSize === 500 && 'current-page-size' }`}>
                        <button
                          onClick={() => {
                            togglePageSizeList()
                            setChosenPageSize(500)
                          }}
                          type="button"
                        >
                          500
                        </button>
                      </li>
                    <li className={`page-size-item ${chosenPageSize === 200 && 'current-page-size' }`}>
                        <button
                          onClick={() => {
                            togglePageSizeList()
                            setChosenPageSize(200)
                          }}
                          type="button"
                        >
                          200
                        </button>
                      </li>
                      <li className={`page-size-item ${chosenPageSize === 100 && 'current-page-size' }`}>
                        <button
                          onClick={() => {
                            togglePageSizeList()
                            setChosenPageSize(100)
                          }}
                          type="button"
                        >
                          100
                        </button>
                      </li>
                      <li className={`page-size-item ${chosenPageSize === 50 && 'current-page-size' }`}>
                        <button
                          onClick={() => {
                            togglePageSizeList()
                            setChosenPageSize(50)
                          }}
                          type="button"
                        >
                          50
                        </button>
                      </li>
                      <li className={`page-size-item ${chosenPageSize === 25 && 'current-page-size' }`}>
                        <button
                          onClick={() => {
                            togglePageSizeList()
                            setChosenPageSize(25)
                          }}
                          type="button"
                        >
                          25
                        </button>
                      </li>
                      <li className={`page-size-item ${chosenPageSize === 20 && 'current-page-size' }`}>
                        <button
                          onClick={() => {
                            togglePageSizeList()
                            setChosenPageSize(20)
                          }}
                          type="button"
                        >
                          20
                        </button>
                      </li>
                      <li className={`page-size-item ${chosenPageSize === 10 && 'current-page-size' }`}>
                        <button
                          onClick={() => {
                            togglePageSizeList()
                            setChosenPageSize(10)
                          }}
                          type="button"
                        >
                          10
                        </button>
                      </li>
                      <li className={`page-size-item ${chosenPageSize === 5 && 'current-page-size' }`}>
                        <button
                          onClick={() => {
                            togglePageSizeList()
                            setChosenPageSize(5)
                          }}
                          type="button"
                        >
                          5
                        </button>
                      </li>
                    </ul>
                  </>
                )}
              </button>
              <ExpandBtn
                onClick={() => setPageSizeListOpen((p) => !p)}
                style={{ width: "1.9rem", height: "1.9rem" }}
              />
            </div>
          </div>
          <button className="submit-button" onClick={onSubmit}>
            Сохранить
          </button>
        </div>
      </div>, root
    )}
    </>
  )
}

export default SettingsModal
