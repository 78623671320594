import { useState, useEffect } from 'react';
import '../../App.css';
import './taskTracker.css';
import NavigationMenu from '../../Components/NavigationMenu';
import TrackerHeader from './MainPage/Header.jsx';
import AllTasksCard from './MainPage/AllTasksCard.jsx';
import PeriodSwitcher from './MainPage/PeriodSwitcher.jsx';
import ModalDelete from '../Service/ModalDelete.jsx';
import { useNavigate } from 'react-router-dom';
import checkTokenExpiry from '../../shared/utils/checkTokenExpiry.js';

const Tracker = ({ accessToken }) => {
    const tasksStatus = {
    planned: 'Запланировано',
    inProgress: 'В работе',
    underReview: 'На проверке',
    underDevelopment: 'На доработке',
    done: 'Выполнено'
  }
  // ['Запланировано', 'В работе', 'На проверке', 'На доработке', 'Выполнено'];
  // const [isDetailedCardOpened, setIsDetailedCardOpened] = useState(false);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    checkTokenExpiry(navigate);
  },[navigate])


  // const onOpenDetailedCard = () => {
  //   setIsDetailedCardOpened(true);
  // }

  // const handleCloseModal = () => {
  //   setIsModalOpen(false);
  // }

  const handleChangeDate = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
  }

 return(
  <>
    <div className='body-wrapper tracker-body-wrapper'>
      <div className="page-wrapper page-flex-wrapper">
        <NavigationMenu currentPage='tracker' accessToken={accessToken}/>
        <main className='tracker-main__wrapper'>
          <TrackerHeader
            startDate={startDate}
            endDate={endDate}
          />
          <PeriodSwitcher
            accessToken={accessToken}
            onChangeDates={handleChangeDate}
          />
          <div className="tracker-main-tasks-cards__container">
          {tasksStatus.map((item, index) => (
            <AllTasksCard
              key={index}
              typeOfTasks={item}
              // onOpen={handleOpenModal}
              startDate={startDate}
              endDate={startDate}
              accessToken={accessToken}
            />
          ))}
          </div>
        </main>
      </div>
      </div>
  </>
 )
}
export default Tracker;
