import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
// import logo from '../../Images/Menu/logo.svg';
import NavigationMenu from '../../Components/NavigationMenu';
import AreasForm from './AreasForm';
import AreaBlock from './AreaBlock';
import '../Service/service.css'
import ModalDelete from './ModalDelete';
import checkTokenExpiry from '../../shared/utils/checkTokenExpiry';

const AreasPage = ({accessToken}) => {

  // const navigate = useNavigate();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    checkTokenExpiry(navigate);
  },[navigate])

  // const handleLogout = () => {
  //   localStorage.removeItem('accessToken');
  //   navigate('/');
  //   window.location.reload();
  // }

  const handleOpenModal = () => {
    setIsModalOpen(true);
  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
  }

  const handleButtonClick = () => {
    setIsFormOpen(!isFormOpen);
    setIsFormVisible(false);
  };

  const handleTransitionEnd = () => {
    if (isFormOpen) {
      setIsFormVisible(true);
    }
  };

  const handleFormSubmit = () => {
    // getDirections();
  };

  return(
    <>
      <div className='body-wrapper'>
      <div className="page-wrapper page-flex-wrapper">
      <NavigationMenu currentPage='service' accessToken={accessToken}/>
        {/* <header className="header-menu header-menu-service">
          <nav className='navigation'>
            <Link to='/' className='logo-monstr'>
              <img src={logo} alt='логотип' width={40} height={80}/>
            </Link>
            <ul className='navigation-list'>
              <li className='navigation-item'>
                <Link to='/load-data-table' className='navigation-item-link navigation-item-table'></Link>
              </li>
              <li className='navigation-item'>
                <Link to='/task-tracker' className='navigation-item-link navigation-item-tracker'></Link>
              </li>
              <li className='navigation-item current'>
                <Link to='/service' className='navigation-item-link  navigation-item-service'></Link>
              </li>
              <li className='navigation-item'>
                <Link to='/apk-download' className='navigation-item-link navigation-item-apk-download'></Link>
              </li>
            </ul>
            <button className='button button-logout2' onClick={handleLogout}></button>
          </nav>
        </header> */}
        <main className='main-index__wrapper 5index'>
        <section className='service-container'>
            <section className='directions-container'>
              <div className='direction-general-info'>
                <h1>Участки</h1>
                <div style={{backgroundColor: '#9B69BA'}} className='direction-item-areas'>УГР</div>
                <button className='button-change-view'></button>
              </div>
              <div className={`areas-wrapper ${isFormOpen ? 'collapsed' : ''}`}>
                <AreaBlock onDeleteClick={handleOpenModal}/>
                <AreaBlock onDeleteClick={handleOpenModal}/>
                <AreaBlock onDeleteClick={handleOpenModal}/>
                <AreaBlock onDeleteClick={handleOpenModal}/>
              </div>
            </section>
        </section>
        <div className='button-form-wrapper'>
        <Link to='/service' className='button-close-areas'></Link>
        <button className={`button-add-direction button-add-direction-areas ${isFormOpen ? 'open' : ''}`} onClick={handleButtonClick} onTransitionEnd={handleTransitionEnd}>+</button>
        </div>

          {isFormVisible && (
            <>
              <button className='button-close-form button-close-form-areas' onClick={handleButtonClick}></button>
              <AreasForm onFormSubmit={handleFormSubmit} accessToken={accessToken} onClose={handleButtonClick}/>
            </>
          )
          }
          {
            isModalOpen && <ModalDelete message='Удалить участок?' action='Удалить' onClose={handleCloseModal}/>
          }
        </main>
      </div>
      </div>
    </>
  )
}
export default AreasPage;
