import '../../../Pages/ShiftsTable/shiftInfoModal.css';
import '../../../Pages/ShiftsTable/block.css';
import apiConfig from '../../../apiConfig.json'
import { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Polyline, Rectangle} from "react-leaflet";
import { Link } from 'react-router-dom';
import L from "leaflet";
import stopPointIconUrl from '../../../Images/route-stop.svg';
// import { handleEscape } from '@shared/functions/handle-escape';
import getImageForEquipmentType from '../../../shared/utils/getImageForEquipmentType';
import getColorByRating from '../utils/getColorByRating';
import calculateBounds from '../utils/calculateBounds';
import formatTripsLabel from '../../../shared/utils/formatTripsLabel';
import formatDateForShift from '../utils/formatDateForShift';
import formatTimeForShift from '../utils/formatTimeForShift';
import convertDurationToSeconds from '../utils/convertDurationToSeconds';
import getDurationOfAllStops from '../utils/getDurationOfAllStops';
import processRouteObject from '../../../shared/utils/processRouteObject';
import calculateShiftDuration from '../utils/calculateShiftDuration';
import getShift from '../api/get-shift';
import getStops from '../api/get-stops';


const Shift = (props) => {

  const {id, accessToken} = props;
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
  const [blockCount, setBlockCount] = useState(0);
  const [showPolygons, setShowPolygons] = useState(false);
  const [showMarkers, setShowMarkers] = useState(true);
  const [showPolylines, setShowPolylines] = useState(true);
  const [mapOpened, setMapOpened] = useState(false);
  const [shift, setShift] = useState(null);
  const [isLocalTime, setIsLocalTime] = useState(false);
  const [shiftState, setShiftState] = useState({
      loading: false,
      shift: null
    })
  const [stopsState, setStopsState] = useState({
    loading: false,
    stopsInfo: null,
  })
  const [stopsSquaresState, setStopsSquaresState] = useState({
    loading: false,
    stopsSquaresInfo: null,
  })

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  useEffect(() => {
    const loadShiftData = async () => {
      try {
        const shift = await getShift(id, accessToken);
        setShift(shift);
      } catch (error) {
        setShift(null);
      }
    };
    loadShiftData();
  }, [id, accessToken]);

  useEffect(() => {
    if(shift !== null && shift.route !== null && Object.keys(shift.route).length > 2) {
        // console.log("shift & route", shift, shift.route)
        const stopsUrl = `${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.GET_STOPS_INFORMATION}`;
        fetch(stopsUrl, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            route: shift.route
        })
        })
          .then((response) => response.json())
          .then((data) => {
            console.log(data)
            setStopsState({
              loading: true,
              stopsInfo: data,
            });
          })
          .catch((error) => {
            console.error('Error fetching user data:', error);
            setStopsState({
              loading: false,
              stopsInfo: null,
            });
          });
  }},[shift, accessToken])

  // useEffect(() => {
  //   if (shift?.route && Object.keys(shift.route).length > 2) {
  //     const loadStops = async () => {
  //       try {
  //         const stops = await getStops(route, accessToken);
  //         setStopsState({
  //           loading: true,
  //           stopsInfo: stops
  //         })
  //       } catch (error) {
  //         setStopsState({
  //           loading: false,
  //           stopsInfo: null
  //         })
  //       }
  //     }
  //     loadStops();
  //   }
  // }, [])

  useEffect(() => {
    if(shift !== null && shift.route !== null && Object.keys(shift.route).length > 2) {
    const stopsUrl = `${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.GET_STOPS_REPORT}`;
    fetch(stopsUrl, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      console.log(data)
      setStopsSquaresState({
        loading: true,
        stopsSquaresInfo: data,
      });
    })
    .catch((error) => {
      console.error('Error fetching user data:', error);
      setStopsSquaresState({
        loading: false,
        stopsSquaresInfo: null,
      });
    });
    }
  },[accessToken])



  if (!shift || !Array.isArray(shift.trips)) {
    console.error("Ошибка: shift.trips отсутствует или не является массивом");
    return [];
  }
  const route = shift.route ? Object.keys(shift.route) : [];
  const shiftRouteKeys = shift.route ? Object.keys(shift.route).sort() : [];
  const sortedMainRoute = {};
  shiftRouteKeys.forEach((key) => {
    sortedMainRoute[key] = shift.route[key];
  });
  const allTripsEmpty = shift.trips.every((trip) => {
    if (trip.route === null || trip.route === undefined) {
      return true;
    }
    return Object.keys(trip.route).length === 0;
  });

  const sortedRoutes = [];
  if (allTripsEmpty) {
    if (shift.route) {
      const shiftRouteKeys = Object.keys(shift.route).sort();
      const sortedRoute = {};
      shiftRouteKeys.forEach((key) => {
        sortedRoute[key] = shift.route[key];
      });
      sortedRoutes.push(sortedRoute);
    }
  } else {
    shift.trips.forEach((trip) => {
      if (!trip.route) return;
      const sortedRouteKey = Object.keys(trip.route).sort();
      const sortedRoute = {};
      sortedRouteKey.forEach((key) => {
        sortedRoute[key] = trip.route[key];
      });
      sortedRoutes.push(sortedRoute);

    });
  }




  const handleBlockCountDown = () => {
    if (blockCount >= 0) {
      setBlockCount(blockCount - 1);
    }
  }

  const handleBlockCountUp = () => {
    if (blockCount <= 2) {
      setBlockCount(blockCount + 1);
      }
  }



  let routes = [];

  if (Array.isArray(sortedRoutes)) {
    routes = sortedRoutes.map((routeObj) => ({
      waypoints: processRouteObject(routeObj),
    }));
  } else if (typeof route === "object" && sortedRoutes !== null) {
    routes = [{ waypoints: processRouteObject(sortedRoutes) }];
  }

  const colors = ["#E5A162", "#664778", "#3D2655", "#568FA2", "#4C5F84"];
  const mainRouteWaypoints = processRouteObject(sortedMainRoute);



  const handleOpenMap = () => {
    setMapOpened(true);
  }

  const handleCloseMap = () => {
    setMapOpened(false);
  }

    const stopPointIcon = new L.icon({
      iconUrl: require('../../../Images/marker.png'),
      iconSize: [10, 15],
      iconAnchor: [5, 15],
    });

    const startIcon = new L.icon({
      iconUrl: require('../../../shared/assets/geo/start-icon.png'),
      iconSize: [15, 20],
      iconAnchor: [1, 20],
    });

    const finishIcon = new L.icon({
      iconUrl: require('../../../shared/assets/geo/finish-icon.png'),
      iconSize: [15, 20],
      iconAnchor: [1, 20],
    });

  return(
    <>
    <div className="info-modal-container">
      <div className="switcher-between-pages change-time-zone" style={{position: 'absolute', top: '1rem', left: '10px'}}>
          <button
            onClick={() => setIsLocalTime(true)}
            className={`switcher-between-pages__button ${
              isLocalTime && "switcher-between-pages__button--current"
            }`}
          >
            <span>Местное время</span>
          </button>

          <button
            onClick={() => setIsLocalTime(false)}
            type="button"
            className={`switcher-between-pages__button ${
              !isLocalTime &&
              "switcher-between-pages__button--current"
            }`}
          >
            <span>Время по мск</span>
          </button>
        </div>

        <Link className="info-modal-container__button--close" to={'/load-data-table'}></Link>
        <div className='info-modal-container__button-wrapper'>
          <button className="info-modal-container__button--prev" onClick={handleBlockCountDown} disabled={blockCount === 0}></button>
          <button className="info-modal-container__button--next" onClick={handleBlockCountUp} disabled={blockCount === 2}></button>
        </div>
        <div className='info-modal-container__blocks-wrapper'>
          {
            mapOpened ? (
              <div className={isMobile ? blockCount === 2 ? 'info-modal-wrapper__block info-modal-wrapper__block-map block' : 'inactive' : 'info-modal-wrapper__block info-modal-wrapper__block-map block'}>
                  <div className="block__controls" style={{ position: 'absolute', bottom: '20px', left: '20px', zIndex: 1000 }}>
                    <label>
                      <input
                        className='visually-hidden control-input'
                        type="checkbox"
                        checked={showPolygons}
                        onChange={() => setShowPolygons(!showPolygons)}
                      />
                      <span className="control-mark"></span>
                      <span className="control-text">Частота остановок</span>
                    </label>
                    <label>
                      <input
                        className='visually-hidden control-input'
                        type="checkbox"
                        checked={showMarkers}
                        onChange={() => setShowMarkers(!showMarkers)}
                      />
                      <span className="control-mark"></span>
                      <span className="control-text">Точки остановок</span>
                    </label>
                    <label>
                      <input
                        className='visually-hidden control-input'
                        type="checkbox"
                        checked={showPolylines}
                        onChange={() => setShowPolylines(!showPolylines)}
                      />
                      <span className="control-mark"></span>
                      <span className="control-text">Рейсы</span>
                    </label>
                  </div>
                  <button
                    className='block__button-map-close'
                    style={{ position: 'absolute', top: '20px', right: '20px', zIndex: 1000 }}
                    onClick={handleCloseMap}></button>
                  <MapContainer center={mainRouteWaypoints[0]} zoom={12} className='shift-leaflet-container'>
                    <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    zoomControl="false"/>
                    {/* <Marker position={[59.888877, 30.482488]} icon={routePointIcon} /> */}
                    {mainRouteWaypoints.length > 0 && (
                    <>
                      {
                        showPolylines && <Polyline key="mainRoute" positions={mainRouteWaypoints} color="grey" weight={1} />
                      }
                      {
                        showPolylines &&
                        <>
                        <Marker position={mainRouteWaypoints[0]} icon={startIcon} />
                        <Marker position={mainRouteWaypoints[mainRouteWaypoints.length - 1]} icon={finishIcon} />
                        </>
                      }
                    </>
                    )}

                    {showPolylines && routes.flatMap((route, index) => {
                      const waypoints = route.waypoints;
                      if(waypoints.length > 0) {
                        return [
                          <Marker key={`${index}-start`} position={waypoints[0]}></Marker>,
                          <Marker key={`${index}-end`} position={waypoints[waypoints.length - 1]}></Marker>
                        ];
                      }
                      return[];
                    })}
                    {
                      showPolylines && <Polyline key={sortedMainRoute} positions={mainRouteWaypoints} color="grey" weight={1}/>
                    }

                    {
                        showMarkers && stopsState.stopsInfo && stopsState.stopsInfo.stops.map((stop, index) => (
                        <Marker key={index} position={[stop.latitude, stop.longitude]} icon={stopPointIcon} />
                      ))
                    }

                    {showPolylines && routes.map((route, index) => (
                      <Polyline key={index} positions={route.waypoints} color={colors[index % colors.length]} weight={1} />
                    ))}

                    {/* <button className="information-container__button-open-map" type="button" onClick={() =>toggleMap([59.5597850, 30.0849539])}></button> */}

                    {
                      stopsSquaresState.loading === true && showPolygons &&
                      stopsSquaresState.stopsSquaresInfo.stop_squares.map((square, index) => {
                      const bounds = calculateBounds(square);
                      const color = getColorByRating(square[2]);
                      return (
                        <Rectangle
                          key={index}
                          bounds={bounds}
                          pathOptions={{ color: 'transparent', fillColor: color, fillOpacity: 0.8 }}
                        />
                    );
                  })}
                  </MapContainer>
                </div>
            ) : (
              <div className='info-modal-container__blocks-inner-wrapper'>
                <div className={isMobile ? blockCount === 0 ? 'info-modal-wrapper__block info-modal-wrapper__block-info-shift block' : 'inactive' : 'info-modal-wrapper__block info-modal-wrapper__block-info-shift block'}>
                  <p className='block__title'>{shift.user.fullName}</p>
                  <ul className='block__info-list list'>
                    <li className='block__info-item'>
                      {
                        shift.equipment === null ? (
                          <img src={getImageForEquipmentType('EXCAVATOR')} alt='equip' width={45} height={45}/>
                        ) : (
                          <img src={getImageForEquipmentType(shift.equipment.equipmentType)} alt='оборудование' width={45} height={45}/>
                        )
                      }
                    </li>
                    <li className='block__info-item'>
                      <p className='block__info-item-title'>Гараж.<br></br>номер</p>
                      {
                        shift.equipment === null ? (
                          <p className='block__info-item-value'>000</p>
                        ) : (
                          <p className='block__info-item-value'>{shift.equipment.warrantyNumber}</p>
                        )
                      }
                    </li>
                    <li className='block__info-item'>
                      <p className='block__info-item-title'>Макс.<br></br>скорость</p>
                      {
                         stopsState.stopsInfo !== null ? (
                          <p className='block__info-item-value'>{stopsState.stopsInfo.max_speed}</p>
                         ) : (
                          <p className='block__info-item-value'>-</p>
                         )
                      }
                    </li>
                    <li className='block__info-item'>
                      <p className='block__info-item-title'>Средняя<br></br>скорость</p>
                      {
                         stopsState.stopsInfo !== null ? (
                          <p className='block__info-item-value'>{stopsState.stopsInfo.avg_speed}</p>
                         ) : (
                          <p className='block__info-item-value'>-</p>
                         )
                      }
                    </li>
                    <li className='block__info-item'>
                      <p className='block__info-item-title'>Дистанция<br></br>(километраж)</p>
                      {
                         stopsState.stopsInfo !== null ? (
                          <p className='block__info-item-value'>{stopsState.stopsInfo.mileage}</p>
                         ) : (
                          <p className='block__info-item-value'>-</p>
                         )
                      }
                    </li>
                  </ul>
                  <div className='block__shift-place-info'>
                    <p>{shift.shiftPlace.name}</p>
                  </div>
                  <div className='block__shift-time-info'>
                    <div className='block__shift-time'>
                      <p className='block__info-item-title'>Начало смены</p>
                      <p>{formatDateForShift(shift.start, isLocalTime)}</p>
                      <p>{formatTimeForShift(shift.start, isLocalTime)}</p>
                    </div>
                    <div className='block__shift-duration-info'>
                      <p className='block__info-item-title'>{calculateShiftDuration(shift.start, shift.end)}</p>
                    </div>
                    <div className='block__shift-time block__shift-time--right'>
                      <p className='block__info-item-title'>Конец смены</p>
                      <p>{formatDateForShift(shift.end, isLocalTime)}</p>
                      <p>{formatTimeForShift(shift.end, isLocalTime)}</p>
                    </div>
                  </div>
                  <div className='block__trips-info'>
                    <p>{formatTripsLabel(shift.trips.length)}</p>
                    <ul className='block__trips-title-list'>
                      <li className='block__trips-title-item block__info-item-title'>
                        <p>Рейс</p>
                      </li>
                      <li className='block__trips-title-item block__info-item-title'>
                        <p>Начало рейса</p>
                      </li>
                      <li className='block__trips-title-item block__info-item-title'>
                        <p>Конец рейса</p>
                      </li>
                      <li className='block__trips-title-item block__info-item-title'>
                        <p>Продолжит. рейса</p>
                      </li>
                    </ul>
                    <div className='block__trips-info-list-wrapper'>
                      {
                        shift.trips.map((trip, index) => {
                          return (
                            <ul key={index} className='block__trips-info-list'>
                              <li className='block__trips-info-item block__info-item-value'>
                                {index + 1}
                              </li>
                              <li className='block__trips-info-item block__info-item-value'>
                                <p>{formatDateForShift(trip.start, isLocalTime)}</p>
                                <p>{formatTimeForShift(trip.start, isLocalTime)}</p>
                              </li>
                              <li className='block__trips-info-item block__info-item-value'>
                                <p>{formatDateForShift(trip.end, isLocalTime)}</p>
                                <p>{formatTimeForShift(trip.end, isLocalTime)}</p>
                              </li>
                              <li className='block__trips-info-item block__info-item-value'>
                                <p>{calculateShiftDuration(trip.start, trip.end)}</p>
                              </li>
                            </ul>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
                <div className={isMobile ? blockCount === 1 ? 'info-modal-wrapper__block info-modal-wrapper__block-info-stops block' : 'inactive' : 'info-modal-wrapper__block info-modal-wrapper__block-info-stops block'}>
                  <p className='block__title'>Информация об остановках</p>
                  <ul className='block__stops-title-list'>
                      <li className='block__stops-title-item block__info-item-title'>
                        <p>Дата <br></br> остановки</p>
                      </li>
                      <li className='block__stops-title-item block__info-item-title'>
                        <p>Время <br></br> остановки</p>
                      </li>
                      <li className='block__stops-title-item block__info-item-title'>
                        <p>Продолжит. <br></br> остановки</p>
                      </li>
                      <li className='block__stops-title-item block__info-item-title'>
                        <p>Широта места <br></br> остановки</p>
                      </li>
                      <li className='block__stops-title-item block__info-item-title'>
                        <p>Долгота места <br></br> остановки</p>
                      </li>
                    </ul>
                    <div className='block__stops-info-list-wrapper'>
                      {
                        (stopsState.loading === false && shift.route && Object.keys(shift.route).length > 2) ? (
                          <p className='block__stops-loading'>Данные по остановкам загружаются</p>
                        ) : (shift.route === null || shift.route === undefined || Object.keys(shift.route).length <= 2) ? (
                          <p className='block__stops-not-existing'>Информация об остановках отсутствует</p>
                        ) : (stopsState.loading === true) ? (
                          <>
                            {stopsState.stopsInfo.stops.map((stop, index) => {
                              return (
                                <ul key={index} className='block__stops-info-list'>
                                  <li className='block__stops-info-item block__info-item-value'>
                                    <p>{formatDateForShift(stop.start, isLocalTime)}</p>
                                  </li>
                                  <li className='block__stops-info-item block__info-item-value'>
                                    <p>{formatTimeForShift(stop.start, isLocalTime)}</p>
                                  </li>
                                  <li className='block__stops-info-item block__info-item-value'>
                                    <p>{stop.duration}</p>
                                  </li>
                                  <li className='block__stops-info-item block__info-item-value'>
                                    <p>{stop.latitude}</p>
                                  </li>
                                  <li className='block__stops-info-item block__info-item-value'>
                                    <p>{stop.longitude}</p>
                                  </li>
                                </ul>
                              )
                            })}

                          </>
                        ) : null
                      }
                    </div>
                    {
                    (stopsState.loading === false && shift.route && Object.keys(shift.route).length > 2) ? (
                      <p></p>
                    ) : (shift.route === null || shift.route === undefined || Object.keys(shift.route).length <= 2) ? (
                      <p></p>
                    ) : (stopsState.loading === true) ? (
                      <>
                       <p className='block__stops-duration'>
                         Общее время продолжительности остановок: {getDurationOfAllStops(stopsState.stopsInfo.stops.reduce((total, stop) => total + convertDurationToSeconds(stop.duration), 0))}
                       </p>

                      </>
                    ) : null
                    }
                </div>
                <div className={isMobile ? blockCount === 2 ? 'info-modal-wrapper__block info-modal-wrapper__block-map block' : 'inactive' : 'info-modal-wrapper__block info-modal-wrapper__block-map block'}>
                  <div className="block__controls" style={{ position: 'absolute', bottom: '20px', left: '20px', zIndex: 1000 }}>
                    <label>
                      <input
                        className='visually-hidden control-input'
                        type="checkbox"
                        checked={showPolygons}
                        onChange={() => setShowPolygons(!showPolygons)}
                      />
                      <span className="control-mark"></span>
                      <span className="control-text">Частота остановок</span>
                    </label>
                    <label>
                      <input
                        className='visually-hidden control-input'
                        type="checkbox"
                        checked={showMarkers}
                        onChange={() => setShowMarkers(!showMarkers)}
                      />
                      <span className="control-mark"></span>
                      <span className="control-text">Точки остановок</span>
                    </label>
                    <label>
                      <input
                        className='visually-hidden control-input'
                        type="checkbox"
                        checked={showPolylines}
                        onChange={() => setShowPolylines(!showPolylines)}
                      />
                      <span className="control-mark"></span>
                      <span className="control-text">Рейсы</span>
                    </label>
                  </div>
                  <button
                    className='block__button-map-open'
                    style={{ position: 'absolute', top: '20px', right: '20px', zIndex: 1000 }}
                    onClick={handleOpenMap}></button>
                  <MapContainer center={mainRouteWaypoints[0]} zoom={12} className='shift-leaflet-container'>
                    <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    zoomControl="false"/>
                    {/* <Marker position={[59.888877, 30.482488]} icon={routePointIcon} /> */}
                    {mainRouteWaypoints.length > 0 && (
                    <>
                      {
                        showPolylines && <Polyline key="mainRoute" positions={mainRouteWaypoints} color="grey" weight={1} />
                      }
                      {
                        showPolylines &&
                        <>
                        <Marker position={mainRouteWaypoints[0]} icon={startIcon} />
                        <Marker position={mainRouteWaypoints[mainRouteWaypoints.length - 1]} icon={finishIcon} />
                        </>
                      }
                    </>
                    )}

                    {showPolylines && routes.flatMap((route, index) => {
                      const waypoints = route.waypoints;
                      if(waypoints.length > 0) {
                        return [
                          <Marker key={`${index}-start`} position={waypoints[0]}></Marker>,
                          <Marker key={`${index}-end`} position={waypoints[waypoints.length - 1]}></Marker>
                        ];
                      }
                      return[];
                    })}
                    {
                      showPolylines && <Polyline key={sortedMainRoute} positions={mainRouteWaypoints} color="grey" weight={1}/>
                    }

                    {
                        showMarkers && stopsState.stopsInfo && stopsState.stopsInfo.stops.map((stop, index) => (
                        <Marker key={index} position={[stop.latitude, stop.longitude]} icon={stopPointIcon} />
                      ))
                    }

                    {showPolylines && routes.map((route, index) => (
                      <Polyline key={index} positions={route.waypoints} color={colors[index % colors.length]} weight={1} />
                    ))}

                    {/* <button className="information-container__button-open-map" type="button" onClick={() =>toggleMap([59.5597850, 30.0849539])}></button> */}

                    {
                      stopsSquaresState.loading === true && showPolygons &&
                      stopsSquaresState.stopsSquaresInfo.stop_squares.map((square, index) => {
                      const bounds = calculateBounds(square);
                      const color = getColorByRating(square[2]);
                      return (
                        <Rectangle
                          key={index}
                          bounds={bounds}
                          pathOptions={{ color: 'transparent', fillColor: color, fillOpacity: 0.8 }}
                        />
                    );
                  })}
                  </MapContainer>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </>
  )
}

let DefaultIcon = L.icon({
  iconUrl: stopPointIconUrl,
  iconSize: [10, 15],
  iconAnchor: [5, 15],
  popupAnchor: [1, -12],
});
L.Marker.prototype.options.icon = DefaultIcon;

export default Shift;
