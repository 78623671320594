import excavator from '../assets/eqipments-icons/excavator-black-icon.svg';
import bulldozer from '../assets/eqipments-icons/bulldozer-black-icon.svg';
import crane from '../assets/eqipments-icons/crane-black-icon.svg';
import grader from '../assets/eqipments-icons/grader-black-icon.svg';
import rink from '../assets/eqipments-icons/rink-black-icon.svg';
import truck from '../assets/eqipments-icons/truck-black-icon.svg';
import pooker from '../assets/eqipments-icons/camera-black-icon.svg';
import auto from '../assets/eqipments-icons/auto-black-icon.svg';
import refueler from '../assets/eqipments-icons/refueler-black-icon.svg';
import bus from '../assets/eqipments-icons/bus-black-icon.svg';
import mule from '../assets/eqipments-icons/mule-black-icon.svg';
import tractor from '../assets/eqipments-icons/tractor-black-icon.svg';
import mixer from '../assets/eqipments-icons/mixer-black-icon.svg';

const getImageForEquipmentType = (equipmentType) => {
  switch (equipmentType) {
    case 'EXCAVATOR':
      return excavator;
    case 'BULLDOZER':
      return bulldozer;
    case 'CRANE':
      return crane;
    case 'GRADER':
      return grader;
    case 'RINK':
      return rink;
    case 'TRUCK':
      return truck;
    case 'POOKER':
      return pooker;
    case 'AUTO':
      return auto;
    case 'REFUELER':
      return refueler;
    case 'BUS':
      return bus;
    case 'MULE':
      return mule;
    case 'TRACTOR':
      return tractor;
    case 'MIXER':
      return mixer;
    default:
      return excavator;
  }
};
export default getImageForEquipmentType;
