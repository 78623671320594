import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoadDataTable from './Services/LoadDataTable';
import LogInAdmin from './Services/LogInAdmin';
import { useState, useEffect } from "react";
import Tracker from './Pages/TaskTracker/Tracker';
import ApkDownload from './Components/ApkDownload';
import DirectionPage from './Pages/Service/DirectionPage';
import AreasPage from './Pages/Service/AreasPage';
import GanttChart from './Pages/TaskTracker/GantChart';
import AdditionEquipPage from './Pages/UserEquipAddition/AdditionEquipPage';
import AdditionUserPage from './Pages/UserEquipAddition/AdditionUserPage';
import MainQuestionsPage from './Pages/FAQ/MainQuestionsPage'
import { ShiftPage } from './Pages/ShiftPage';

function App() {

  const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken') || '');

  const handleLogin = (token) => {
    setAccessToken(token);
    localStorage.setItem('accessToken', token);
  }

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      setAccessToken(token);
    }
  }, []);


  return (
    <>
      {/* <Router>
        <Routes>
          <Route exact path="/" element={accessToken ? <AdminMenu accessToken={accessToken}/> : <LogInAdmin onLogin={handleLogin}/>} />
          <Route path="/load-data-table" element={accessToken ? <LoadDataTable accessToken={accessToken}/> : <LogInAdmin onLogin={handleLogin}/>} />
          <Route path="/task-tracker" element={<Tracker accessToken={accessToken}/>}/>
          <Route path="/apk-download" element={<ApkDownload autoDownload/>}/>
        </Routes>
      </Router> */}
      {/* {accessToken ? <LoadDataTable accessToken={accessToken}/> : <LogInAdmin onLogin={handleLogin}/>} */}
      <Router>
      <Routes>
          <Route exact path="/" element={accessToken ? <LoadDataTable accessToken={accessToken}/> : <LogInAdmin onLogin={handleLogin}/>} />
          <Route path="/load-data-table" element={accessToken ? <LoadDataTable accessToken={accessToken}/> : <LogInAdmin onLogin={handleLogin}/>} />
          <Route path="/shift/:id" element={accessToken ? <ShiftPage accessToken={accessToken}/> : <LogInAdmin onLogin={handleLogin}/>}/>
          <Route path="/service" element={accessToken ? <DirectionPage accessToken={accessToken}/> : <LogInAdmin onLogin={handleLogin}/>} />
          <Route path="/service-areas" element={accessToken ? <AreasPage accessToken={accessToken}/> : <LogInAdmin onLogin={handleLogin}/>} />
          <Route path="/gantt-chart" element={accessToken ? <GanttChart accessToken={accessToken}/> : <LogInAdmin onLogin={handleLogin}/>}/>
          <Route path="/task-tracker" element={accessToken ? <Tracker accessToken={accessToken}/> : <LogInAdmin onLogin={handleLogin}/>}/>
          <Route path="/addition-equip" element={accessToken ? <AdditionEquipPage accessToken={accessToken}/> : <LogInAdmin onLogin={handleLogin}/>}/>
          <Route path="/addition-user" element={accessToken ? <AdditionUserPage accessToken={accessToken}/> : <LogInAdmin onLogin={handleLogin}/>}/>
          <Route path="/apk-download" element={<ApkDownload autoDownload/>}/>
          <Route path="/faq" element={<MainQuestionsPage />}/>
        </Routes>
      </Router>
    </>
  );
}

export default App;
