import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom"
import VinPopUp from "./Desktop/VinPopUp";
import img from '../../Images/icons8-img-100.png';
import MapPopup from "./MapPopup";
import GraphPopup from "./GraphPopup";
import LoadExcelFile from "./LoadExcelFile";
import UserInfoModal from "./UserInfoModal";
import EquipInfoModal from "./EquipInfoModal";
import ShiftInfoModal from "./ShiftInfoModal";
import getImageForEquipmentType from "../../shared/functions/getImageForEquipmentType";
import SettingsModal from "./ui/SettingsModal"
import './ui/_shift-table.scss'

const Main = (props) => {
  const {
    shifts,
    pagination,
    toggleGalleryPopUp,
    accessToken,
    activeColumn,
    loadingSettings,
    applyLoadingSettings,
    onPageSizeChange,
  } = props;

  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);
  const [showVinPopUp, setShowVinPopUp] = useState(false);
  const [showMapPopup, setShowMapPopup] = useState(false);
  const [showGraphPopup, setShowGraphPopup] = useState(false);
  const [showUserInfoModal, setShowUserInfoModal] = useState(false);
  const [showEquipInfoModal, setShowEquipInfoModal] = useState(false);
  const [shownShiftInfoModal, setShowShiftInfoModal] = useState(false);
  const [shiftInfo, setShiftInfo] = useState({});
  const [user, setUser] = useState('');
  const [equip, setEquip] = useState('');
  const vinButtonRef = useRef(null);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [place, setPlace] = useState('');
  const [direction, setDirection] = useState('');
  const [vin, setVin] = useState('');
  const [model, setModel] = useState('');
  const [warrantyNumber, setWarrantyNumber] = useState('');
  const [technicType, setTechnicType] = useState('');
  const [imagesArray, setImagesArray] = useState([]);
  const [mainRoute, setMainRoute] = useState({});
  const [route, setRoute] = useState({});
  const [voltage, setVoltage] = useState({});
  const [expandedTrips, setExpandedTrips] = useState({});
  const [expandedTripsIndex, setExpandedTripsIndex] = useState(null);
  const [pageSizeListOpen, setPageSizeListOpen] = useState(false);
  const pageSizeRef = useRef(null);
  const shiftsDataExcelArray = [];
  const [isLocalTime, setIsLocalTime] = useState(false)
  const [isSettingsModalOpened, setIsSettingsModalOpened] = useState(false)

  // console.log('shifts: ', shifts)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const toggleTrips = (index) => {
    setExpandedTrips(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
    setExpandedTripsIndex(index === expandedTripsIndex ? null : index);
  };

  const formatTripsLabel = (tripsCount) => {
    const lastDigit = tripsCount % 10;
    const lastTwoDigits = tripsCount % 100;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
      return `${tripsCount} Рейсов`;
    } else if (lastDigit === 1) {
      return `${tripsCount} Рейс`;
    } else if (lastDigit >= 2 && lastDigit <= 4) {
      return `${tripsCount} Рейса`;
    } else {
      return `${tripsCount} Рейсов`;
    }
  }

  const toggleUserInfoModal = (user, equipmentType, equipmentWarrantyNum, date, start, end, place) => {
    setShowUserInfoModal(!showUserInfoModal);
    setUser(user);
    setWarrantyNumber(equipmentWarrantyNum);
    setTechnicType(equipmentType);
    setStartDate(date);
    setStartTime(start);
    setEndTime(end);
    setPlace(place);
  }

  const toggleEquipInfoModal = (equip, warrantyNum, user, equipmentType, imagesArray, date, start, end, direction, place) => {
    setShowEquipInfoModal(!showEquipInfoModal);
    setEquip(equip);
    setWarrantyNumber(warrantyNum);
    setUser(user);
    setTechnicType(equipmentType);
    setImagesArray(imagesArray)
    setStartDate(date);
    setStartTime(start);
    setEndTime(end);
    setDirection(direction);
    setPlace(place);
  }

    const toggleMapPopup = (mainRoute, route) => {
    setShowMapPopup(!showMapPopup);
    setMainRoute(mainRoute);
    setRoute(route);
  }

  const toggleGraphPopup = (voltage) => {
    setShowGraphPopup(!showGraphPopup);
    setVoltage(voltage)
  }

  const toggleShiftInfoModal = (shift, mainRoute, route) => {
    setShowShiftInfoModal(!shownShiftInfoModal);
    setShiftInfo(shift);
    setMainRoute(mainRoute);
    setRoute(route);
  }

  const transformShift = (shift) => {
    return {
      end: new Date(shift.end + 'Z').toISOString().slice(0, -1),
      equipmentWarrantyNumber: shift.equipment.warrantyNumber,
      route: shift.route,
      shiftDirectionName: shift.shiftPlace.directionName,
      shiftPlaceName: shift.shiftPlace.name,
      start: new Date(shift.start + 'Z').toISOString().slice(0, -1),
      trips: shift.trips.map(trip => ({
        end: new Date(trip.end + 'Z').toISOString().slice(0, -1),
        start: new Date(trip.start + 'Z').toISOString().slice(0, -1),
        tripPlaceName: trip.tripPlace.name
      })),
      userFullName: shift?.user?.fullName || 'Неопознанный пользователь'
    }
  };

  const togglePageSizeList = () => {
    setPageSizeListOpen(!pageSizeListOpen);
  }

  const handlePageSizeClick = (size) => {
    onPageSizeChange(size);
    togglePageSizeList();
  };

  const onToggleSettingsModal = () => {
    setIsSettingsModalOpened(p => !p)
  }

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (pageSizeRef.current && !pageSizeRef.current.contains(event.target)) {
        setPageSizeListOpen(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [setPageSizeListOpen]);

  if (!shifts || shifts.length === 0) return (
    <>
      <div className="loading-data-shift-table">
        <p>
          Данные не были найдены
        </p>
      </div>
    </>
  )

  return(
    <>
    <MapPopup show={showMapPopup} onClose={toggleMapPopup} mainRoute={mainRoute} route={route}/>
    <GraphPopup show={showGraphPopup} onClose={toggleGraphPopup} voltage={voltage}/>
    <UserInfoModal show={showUserInfoModal} onClose={toggleUserInfoModal} userName={user} equipmentType={technicType} equipmentWarrantyNum={warrantyNumber} date={startDate} start={startTime} end={endTime} place={place} isLocalTime={isLocalTime} accessToken={accessToken}/>
    <EquipInfoModal show={showEquipInfoModal} onClose={toggleEquipInfoModal} equip={equip} accessToken={accessToken} warrantyNumber={warrantyNumber} user={user} equipmentType={technicType} imagesArray={imagesArray} date={startDate} start={startTime} end={endTime} direction={direction} isLocalTime={isLocalTime} place={place}/>
    <ShiftInfoModal show={shownShiftInfoModal} onClose={toggleShiftInfoModal} shift={shiftInfo} mainRoute={mainRoute} route={route} isLocalTime={isLocalTime} setIsLocalTime={setIsLocalTime} accessToken={accessToken}/>
    {isSettingsModalOpened &&
      <SettingsModal
        loadingSettings={loadingSettings}
        applyLoadingSettings={applyLoadingSettings}
        onClose={onToggleSettingsModal}
      />
    }

    <div className="page-wrapper">
        <VinPopUp
          show={showVinPopUp}
          onClose={() => setShowVinPopUp(false)}
          top={top}
          left={left}
          vin={vin}
          model={model}
          warrantyNumber={warrantyNumber}
          technicType={technicType}
          imagesArray={imagesArray}
          toggleGalleryPopUp={toggleGalleryPopUp}
          />
        <main className="main-container">
          <section className={isMobile ? 'main-table-mobile' : 'main-table'}>
            <div className='table'>
              {shifts.map((shift, index) => {
                // console.log(shift.voltageExists)
                  const key = index;

                  if(shift.equipment !== null) {
                    shiftsDataExcelArray.push(transformShift(shift))
                  }

                  const user = shift?.user?.fullName || 'Неопознанный пользователь';
                  const equipment = shift.equipment;

                  const replaceEquipmentVin = () => {
                    if (equipment === null || equipment === undefined) {
                      return 1324234;
                    } else {
                      return equipment.vin;
                    }
                  }
                  const equipmentVin = replaceEquipmentVin();

                  const replaceEquipmentWarrantyNum = () => {
                    if (equipment === null || equipment === undefined) {
                      return '000';
                    } else {
                      return equipment.warrantyNumber;
                    }
                  }
                  const equipmentWarrantyNum = replaceEquipmentWarrantyNum();

                  const replaceEquipmentType = () => {
                    if (equipment === null || equipment === undefined) {
                      return 'EXCAVATOR';
                    } else {
                      return equipment.equipmentType;
                    }
                  }
                  const equipmentType = replaceEquipmentType();

                  const imagesArray = Object.keys(shift.photosImagePath).map(key => ({key, value: shift.photosImagePath[key]}));

                  const formatDate = (dateString) => {
                    const date = new Date(dateString)
                    if (isLocalTime) {
                      date.setHours(date.getHours() + 7)
                    }

                    const day = String(date.getDate()).padStart(2, "0")
                    const month = String(date.getMonth() + 1).padStart(2, "0")
                    const year = date.getFullYear();

                    return `${day}.${month}.${year}`
                  }

                  const formatTime = (dateString) => {
                    // console.log('dateString: ', dateString)
                    const date = new Date(dateString)

                    if (isLocalTime) {
                      date.setHours(date.getHours() + 7)
                    }

                    const hours = String(date.getHours()).padStart(2, '0')
                    const minutes = String(date.getMinutes()).padStart(2, '0')
                    return `${hours}:${minutes}`
                  }

                  const trips = shift.trips;
                  const tripsCount = trips.length;

                  const tripsImages = [];
                  shift.trips?.forEach(trip => {
                    if(trip.photosImagePath) {
                      const tripsPhotos = Object.keys(trip.photosImagePath).map(key => ({key, value: trip.photosImagePath[key]}));
                      tripsImages.push(...tripsPhotos);
                    }
                  });
                  let tripsImage = tripsImages.length > 0 && equipmentType !== 'AUTO' ? tripsImages[0] : img;

                  // let voltage = []
                  // let sortedVoltage = {};
                  // if (shift.voltage !== null && shift.voltage !== undefined && Object.keys(shift.voltage).length > 0) {
                  //   voltage = Object.keys(shift.voltage)
                  //   // console.log(voltage)
                  //   const sortedVoltageKeys = Object.keys(shift.voltage).sort();
                  //   sortedVoltageKeys?.forEach(key => {
                  //     sortedVoltage[key] = shift.voltage[key];
                  //   });

                  // }

                  let isWarningIconShown = false
                  if (shift.technicalBreaks.length > 0) {
                    isWarningIconShown = true
                    // setIsWarningIconShown(true)
                  }


                  const route = shift.route ? Object.keys(shift.route) : [];
                  const shiftRouteKeys = shift.route ? Object.keys(shift.route).sort() : [];
                  const sortedMainRoute = {};
                  shiftRouteKeys.forEach(key => {
                    sortedMainRoute[key] = shift.route[key];
                  });


                  const allTripsEmpty = shift.trips.every(trip => {
                    if (trip.route == null || trip.route === undefined) {
                      return true;
                    }
                    const sortedRouteKey = Object.keys(trip.route);
                    return sortedRouteKey.length === 0;
                  })

                  const sortedRoutes = [];

                  if (allTripsEmpty) {
                    const shiftRouteKeys = shift.route ? Object.keys(shift.route) : [];
                    const sortedRoute = {};
                    shiftRouteKeys.forEach(key => {
                      sortedRoute[key] = shift.route[key];
                    });

                  } else {
                    shift.trips.forEach(trip => {
                      if (trip.route == null || trip.route === undefined) {
                        return true;
                      }
                      const sortedRouteKey = Object.keys(trip.route).sort();
                      const sortedRoute = {};
                      sortedRouteKey.forEach(key => {
                        sortedRoute[key] = trip.route[key];
                      });
                      sortedRoutes.push(sortedRoute);
                    });
                  }

                  return (
                  <>
                    <div className='table-body' key={key}>
                          <div className={isMobile ? 'table-row--primary table-row--primary-mobile' : 'table-row--primary'}>
                            <ul className="table-row-list table-row-list--primary">
                              <li
                                className={isMobile ? 'inactive' : 'table-row-item'}
                                rowSpan={tripsCount + 2}
                                style={{paddingLeft: '0.8rem'}}
                              >
                                {isWarningIconShown &&
                                  <button className="shift-table__warning-button" />
                                }
                              </li>
                              <li className={isMobile ? 'table-row-item table-row-item-mobile' : 'table-row-item'} rowSpan={tripsCount + 2}>
                                <button className="table-row-item__button--user" onClick={() => toggleUserInfoModal(shift.user.name, equipmentType, equipmentWarrantyNum, formatDate(shift.start), formatTime(shift.start), formatTime(shift.end), shift.shiftPlace.name)}>
                                  {user}
                                </button>
                              </li>
                              <li className={isMobile ? activeColumn === 0  ? 'table-row-item table-row-item-mobile' : 'inactive' : 'table-row-item table-row-item__equipment-cell'} rowSpan={tripsCount + 2}>
                                {
                                  isMobile ? (
                                    <button className="vin-pop-up" ref={vinButtonRef}
                                      style={{backgroundImage: `url(${getImageForEquipmentType(equipmentType)})`}}
                                      onClick={() => toggleEquipInfoModal(equipmentVin, equipmentWarrantyNum, user,  equipmentType, imagesArray, formatDate(shift.start), formatTime(shift.start), formatTime(shift.end), shift.shiftPlace.directionName, shift.shiftPlace.name)}>
                                      <p>{equipmentWarrantyNum}</p>
                                    </button>
                                  ) : (
                                    <button className="vin-pop-up" ref={vinButtonRef}
                                    style={{backgroundImage: `url(${getImageForEquipmentType(equipmentType)})`}}

                                    onClick={() => toggleEquipInfoModal(equipmentVin, equipmentWarrantyNum, user,  equipmentType, imagesArray, formatDate(shift.start), formatTime(shift.start), formatTime(shift.end), shift.shiftPlace.directionName, shift.shiftPlace.name)}>
                                      <p>{equipmentWarrantyNum}</p>
                                    </button>
                                  )
                                }
                              </li>
                              <li className={isMobile ? activeColumn === 1  ? 'table-row-item table-row-item-mobile' : 'inactive' : 'table-row-item'}>
                                {formatDate(shift.start)}
                              </li>
                              <li className={isMobile ? activeColumn === 2  ? 'table-row-item table-row-item-mobile' : 'inactive' : 'table-row-item'}>
                                {formatTime(shift.start)}
                              </li>
                              <li className={isMobile ? activeColumn === 3  ? 'table-row-item table-row-item-mobile' : 'inactive' : 'table-row-item'}>
                                {formatTime(shift.end)}
                              </li>
                              <li className={isMobile ? activeColumn === 4  ? 'table-row-item table-row-item-mobile' : 'inactive' : 'table-row-item'}>
                                <p>{shift.shiftPlace.directionName}</p>
                              </li>
                              <li className={isMobile ? activeColumn === 5  ? 'table-row-item table-row-item-mobile' : 'inactive' : 'table-row-item'}>
                                {shift.shiftPlace.name}
                              </li>
                              <li className={isMobile ? 'inactive' : 'table-row-item'}>
                                <button className={ expandedTrips[index] ? 'button-races button-races-opened' : 'button-races button-races-closed'} onClick={() => toggleTrips(index)}>
                                    {formatTripsLabel(tripsCount)}
                                </button>
                              </li>
                              <li className={isMobile ? 'inactive' : 'table-row-item'}></li>
                              {
                                expandedTrips[index] ? (
                                  <li className={isMobile ? activeColumn === 6  ? 'table-row-item table-row-item-mobile' : 'inactive' : 'table-row-item'}>
                                        <button className="button-gallery" onClick={() => toggleGalleryPopUp(tripsImages, tripsImage)}>
                                          {equipmentType === 'AUTO' ? (
                                            <img className="fill-image" src={img} width={55} height={35} alt='фото смены'/>
                                          ) : (
                                            <img src={tripsImage.value} width={55} height={35} alt='фото смены' loading="lazy"/>
                                          )}
                                        </button>
                                  </li>
                                ) : (
                                  <li className={isMobile ? activeColumn === 6  ? 'table-row-item table-row-item-mobile' : 'inactive' : 'table-row-item not-expanded'} rowSpan={tripsCount + 2}>
                                        <button className="button-gallery" onClick={() => toggleGalleryPopUp(tripsImages, tripsImage)}>
                                        {equipmentType === 'AUTO' ? (
                                            <img className="fill-image" src={img} width={55} height={35} alt='фото смены'/>
                                          ) : (
                                            <img src={tripsImage.value} width={55} height={35} alt='фото смены' loading="lazy"/>
                                          )}
                                        </button>
                                  </li>
                                )
                              }
                              {
                                expandedTrips[index] ? (
                                  <li className={isMobile ? activeColumn === 7  ? 'table-row-item table-row-item-mobile' : 'inactive' : 'table-row-item'}>
                                    {
                                      route.length === 0 || allTripsEmpty === 0 ? (
                                        <p>-</p>
                                      ) : (
                                        <button className="button-show-map" onClick={() => toggleMapPopup(sortedMainRoute, sortedRoutes)}></button>
                                      )
                                    }
                                  </li>
                                ) : (
                                  <li className={isMobile ? activeColumn === 7  ? 'table-row-item table-row-item-mobile' : 'inactive' : 'table-row-item not-expanded'} rowSpan={tripsCount + 2}>
                                    {
                                      route.length === 0 || allTripsEmpty === 0 ? (
                                        <p>-</p>
                                      ) : (
                                        <button className="button-show-map" onClick={() => toggleMapPopup(sortedMainRoute, sortedRoutes)}></button>
                                      )
                                    }
                                  </li>
                                )
                              }
                              <li className={isMobile ? activeColumn === 8  ? 'table-row-item table-row-item-mobile' : 'inactive' : 'table-row-item'} rowSpan={tripsCount + 2}>
                                {
                                  shift.voltageExists ? (
                                    <button className="button-show-graph" />
                                  ) : (
                                    <p>-</p>
                                    // <button className="button-show-graph" onClick={() => toggleGraphPopup(sortedVoltage)}></button>
                                  )
                                }
                              </li>
                              <li className={isMobile ? 'inactive' : 'table-row-item'} rowSpan={tripsCount + 2}>
                                <Link
                                to={'/shift/' + shift.id}
                                className="button-show-shift-info"
                                // onClick={() => toggleShiftInfoModal(shift, sortedMainRoute, sortedRoutes)}
                                accessToken={accessToken}
                                >
                                </Link>
                              </li>
                              <li className={isMobile ? 'table-row-item table-row-item-mobile' : 'inactive'}>
                                <div className="button-info-wrapper">
                                  <Link className="button-more-info"
                                    to={'/shift/' + shift.id}
                                    accessToken={accessToken}
                                    // onClick={() => toggleShiftInfoModal(shift, sortedMainRoute, sortedRoutes)}
                                  ></Link>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className="expanded-rows-wrapper">
                          {
                            expandedTrips[index] && trips.sort((a, b) => new Date(a.start) - new Date(b.start)).map((trip, index) => {
                              const key = index;
                              const tripWorktype = trip.workType;
                              const tripImagesArray = Object.keys(trip.photosImagePath).map(key => ({key, value: trip.photosImagePath[key]}));
                              const tripImage = tripImagesArray[0];
                              const route = trip.route ? Object.keys(trip.route) : [];
                              const sortedRouteKey = trip.route ? Object.keys(trip.route).sort() : [];
                              const sortedRoute = {};
                              sortedRouteKey.forEach(key => {
                                sortedRoute[key] = trip.route[key];
                              });

                              return(
                              <div className={isMobile ? 'inactive' : 'table-row-expanded'} key={key}>
                                <ul className="table-row-list--primary">
                                  <li className='table-row-item'></li>
                                  <li className='table-row-item'></li>
                                  <li className='table-row-item'></li>
                                  <li className='table-row-item trips-opened-cell'>{formatDate(trip.start)}</li>
                                  <li className='table-row-item trips-opened-cell'>{formatTime(trip.start)}</li>
                                  <li className='table-row-item trips-opened-cell' >{formatTime(trip.end)}</li>
                                  <li className='table-row-item'>{trip.tripPlace.directionName}</li>
                                  <li className='table-row-item'>{trip.tripPlace.name}</li>
                                  <li className='table-row-item'></li>
                                  <li className='table-row-item'>{tripWorktype}</li>
                                  <li className='table-row-item'>
                                    <button className="button-gallery" onClick={() => {toggleGalleryPopUp(tripImagesArray, tripImage);}}>
                                          {equipmentType === 'AUTO' ? (
                                            <img className="fill-image" src={img} width={55} height={35} alt='фото смены'/>
                                          ) : (
                                            <img src={tripImage.value} width={55} height={35} alt='фото смены' loading="lazy"/>
                                          )}
                                    </button>
                                  </li>
                                  <li className="table-row-item">
                                    {
                                      route.length === 0 ? (
                                        <p>-</p>
                                      ) : (
                                        <button className="button-show-map" onClick={() => toggleMapPopup(sortedMainRoute, sortedRoute)}></button>
                                      )
                                    }
                                  </li>
                                  <li className='table-row-item'></li>
                                </ul>
                              </div>
                              )
                            })
                          }
                          </div>

                    </div>
                    </>
                    )
                  }
                )
              }
            </div>
            {
              isMobile ? (
                <>
                </>
              ) : (
                <></>
              )
            }
            <section className={isMobile ? 'table-footer__wrapper table-footer__wrapper-mobile' : "table-footer__wrapper"}>
              <LoadExcelFile accessToken={accessToken} shiftDataExcel=  {shiftsDataExcelArray}
              />
              <button className="shift-table__button button--box-shadow"
                onClick={onToggleSettingsModal}
              >
                Настройки отображения смен
              </button>

              {pagination}
            </section>
          </section>
        </main>
      </div>
    </>
  );
};

export default Main;
