import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import Shift from "../../../entities/shift/ui/Shift";
import checkTokenExpiry from "../../../shared/utils/checkTokenExpiry";
import NavigationMenu from "../../../Components/NavigationMenu";


const ShiftPage = ({accessToken}) => {

  const {id} = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    checkTokenExpiry(navigate);
    const intervalId = setInterval(checkTokenExpiry(navigate), 30000);
    return () => clearInterval(intervalId);
  },[navigate])

  return(
    <>
      <div className="body-wrapper">
        <div className="body-wrapper--flex">
          <NavigationMenu/>
          <main className="addition-main-index">
            <div className="shift-container page-container--dark-bg">
              <Shift id={id} accessToken={accessToken}/>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}
export default ShiftPage;
