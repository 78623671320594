const formatTripsLabel = (tripsCount) => {
  const lastDigit = tripsCount % 10;
  const lastTwoDigits = tripsCount % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
    return `${tripsCount} Рейсов`;
  } else if (lastDigit === 1) {
    return `${tripsCount} Рейс`;
  } else if (lastDigit >= 2 && lastDigit <= 4) {
    return `${tripsCount} Рейса`;
  } else {
    return `${tripsCount} Рейсов`;
  }
}
export default formatTripsLabel;
