import { useState } from "react"
// import { roles } from "@app/store/keywords"
import { ExpandBtn } from "../../../widgets/buttons/index"

export const RolesList = (props) => {
  const { role, setRole, isBlackAndWhite = false, style } = props
  
  const roles = {
    HR: "Работник с персоналом",
    MECHA: "Исполнитель работ",
    USER: "Рабочий",
    ANAL: "Аналитик",
    LOCAL_ADMIN_DIR: "Админ. направления",
    LOCAL_ADMIN_PLACE: "Админ/ участка"
  }

  const [isListExpanded, setIsListExpanded] = useState(false)

  const onChangeStatus = (newRole) => {
    setRole(newRole)
    setIsListExpanded(false)
  }
  return (
    <div style={style} className="roles-list">
      <div
        className={`roles-list__field
        ${isBlackAndWhite && "roles-list--dark-mode__field"}
        ${
          isBlackAndWhite &&
          isListExpanded &&
          "roles-list--dark-mode__field_expanded"
        }
        `}
      >
        {role === 'SERVICE' ? //скрытая роль
          <span className="roles-list__text">SERVICE</span>
          : 
          <span className="roles-list__text">{roles[role]}</span>
        }
        <ExpandBtn
          blackOrWhite={`${isBlackAndWhite ? "white" : "black"}`}
          onClick={() => setIsListExpanded((p) => !p)}
          style={{ padding: "10px" }}
        />
      </div>

      {isListExpanded && (
        <ul
          className={`roles-list__list ${
            isBlackAndWhite && "roles-list--dark-mode"
          }`}
        >
          {Object.keys(roles).map((item) => (
            <>
              {role !== item && (
                <li
                  key={item}
                  className="roles-list__item"
                  onClick={() => onChangeStatus(item)}
                >
                  <span className="roles-list__text">{roles[item]}</span>
                </li>
              )}
            </>
          ))}
        </ul>
      )}
    </div>
  )
}