const cleanWaypoints = (routes) => {
  const uniqueWaypoints = routes.filter((point, index) => {
    return (
      index ===
      routes.findIndex((p) => p[0] === point[0] && p[1] === point[1])
    );
  });
  return uniqueWaypoints.filter(point => {
    return point.length === 2 && !isNaN(point[0]) && !isNaN(point[1]);
  });
}

const processRouteObject = (routeObj) => {
  const waypoints = [];
  for (const key in routeObj) {
    if (routeObj.hasOwnProperty(key)) {
      const value = routeObj[key];
      if (typeof value === "string" && value.includes(",")) {
        const [lat, lng] = value.split(",").map((coordinates) => parseFloat(coordinates.trim()));
        waypoints.push([lat, lng]);
      }
    }
  }
  return cleanWaypoints(waypoints);
}

export default processRouteObject;
