import { useEffect } from "react"

const useEscapeKey = (closeFunc) => {
  // console.log('зашли')
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape')
      // console.log('закрыли чета')
        closeFunc()
    }

    document.addEventListener('keydown', handleEscape)
    // console.log('всё ещё в хуке')
    return () => document.removeEventListener('keydown', handleEscape)

  }, [closeFunc])
}

export default useEscapeKey