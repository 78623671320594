import { useEffect, useState, useCallback } from "react"
import apiConfig from "../../apiConfig.json"
import { RolesList } from "../../widgets/roles-list/index"
import "../ShiftsTable/role-suggestions.css"
import { DistrictsList } from "../../entities/districts"
const UserChangeForm = (props) => {
  const {
    accessToken,
    editingMode,
    onFormSubmit,
    userName,
    userInfo
  } = props

  // console.log(userInfo)

  //все проверки определяют, является дистрикт направлением или участком
  const initialDistrictData = (userInfo.placeName && userInfo.directionName) ? { 
    type: 'place',
    placeName: userInfo.placeName || null,
    directionName: userInfo.directionName|| null
  } : {
    type: 'direction',
    directionName: userInfo.directionName || null,
  }

  const [equipmentList, setEquipmentList] = useState([])
  const [newRole, setNewRole] = useState(userInfo.roles[0])
  const [newPassword, setNewPassword] = useState('')
  const [newDistrict, setNewDistrict] = useState(initialDistrictData)
  const [newImei, setNewImei] = useState(userInfo.imei)

  const EQUIPS = [
    "EXCAVATOR",
    "BULLDOZER",
    "CRANE",
    "GRADER",
    "RINK",
    "TRUCK",
    "REFUELER",
    "AUTO",
    'BUS',
    "POOKER"
  ]

  useEffect(() => {
    // Extract the type values from userInfo's equipment array
    const equipmentTypes = userInfo.specializations.map((equip) => equip)
    setEquipmentList(equipmentTypes)
    console.log(equipmentList)
  }, [userInfo])

  const handleSwitchEquipment = (type) => {
    setEquipmentList((prevList) => {
      if (!prevList.includes(type)) {
        return [...prevList, type]
      } else {
        return prevList.filter((item) => item !== type)
      }
    })
  }

  const prepareUserUpdateData = () => {
    console.log(equipmentList)
    const currentDistrict = newDistrict
    delete currentDistrict.type
    const updateData = {
      name: userName,
      password: newPassword.length > 0 ? newPassword : null, //так надо
      fullName: userInfo.fullName,
      imei: newImei || userInfo.imei,
      specializations: equipmentList.length > 0 ? equipmentList : [],
      roles: [newRole] || userInfo.roles,
      ...currentDistrict
    }

    if (newPassword) {
      updateData.password = newPassword
    }

    return updateData
  }

  const handleEditingMode = () => {
    const updateData = prepareUserUpdateData()
    console.log('updateData: ', updateData)

    const url = `${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.CHANGE_USER_INFORMATION}`
    fetch(url, {
      method: "PUT",
      headers: {
        // Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(updateData)
    })
      .then((response) => {
        onFormSubmit()
        editingMode()
        return response.json()
      })
      .catch((error) => {
        console.error("Error fetching data: ", error)
      })
  }

  const handleInputPasswordChange = (event) => {
    setNewPassword(event.target.value)
  }

  const handleInputImeiChange = (event) => {
    setNewImei(event.target.value)
  }

  return (
    <>
      <div className="information-container__personal-info information-container__personal-info--editing personal-info">
        <div>
          <dt className="personal-info__topic">Логин</dt>
          <span className="personal-info__text personal-info-login">
            {userInfo.username}
          </span>
        </div>
        <dl>
          <div>
            <dt className="personal-info__topic">Должность</dt>
            <RolesList
              style={{zIndex: '30'}}
              role={newRole}
              setRole={setNewRole}
              isBlackAndWhite={true}
            />
          </div>
          <div className="">
          <dt className="personal-info__topic">Направление/Участок</dt>
              <DistrictsList
                style={{zIndex: '10'}}
                heading="Направление/Участок"
                accessToken={accessToken}
                district={newDistrict}
                setDistrict={setNewDistrict}
                isBlackAndWhite={true}
              />
          </div>
          <div>
            <dt className="personal-info__topic">Пароль</dt>
            <input
              className="personal-info__input"
              value={newPassword}
              placeholder="***********"
              onChange={handleInputPasswordChange}
            ></input>
          </div>
          <div className="information-container__equiment information-container__equiment--editing">
            <dt className="personal-info__topic">Оборудование</dt>
            <div className="personal-info__equip-buttons">
              {EQUIPS.map((equip, index) => {
                return (
                  <button
                    key={index}
                    type="button"
                    className={`personal-info__equip-button modal-change-equipment technic-type-${equipmentList.includes(equip) ? "white" : "black"}-${equip.toLowerCase()} ${equipmentList.includes(equip) ? "selected" : ""}`}
                    onClick={(e) => {
                      handleSwitchEquipment(e.target.value)
                    }}
                    value={equip}
                  />
                )
              })}
            </div>
          </div>
        </dl>
      </div>
      <div className="information-container__other-parameters information-container__other-parameters--editing ">
        <div>
          <dt className="personal-info__topic personal-info__topic--imei">
            IMEI
          </dt>
          <div className="personal-info__imei-wrapper">
            <input
              className="personal-info__input personal-info__input--imei"
              placeholder={userInfo.imei}
              value={newImei}
              onChange={handleInputImeiChange}
            ></input>
          </div>
        </div>
      </div>
      {editingMode && (
        <button
          className="personal-info__button-safe"
          onClick={handleEditingMode}
        >
          Coxpaнить
        </button>
      )}
    </>
  )
}
export default UserChangeForm