const calculateShiftDuration = (start, end) => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const durationInMilliseconds = endDate - startDate;
  const seconds = Math.floor((durationInMilliseconds / 1000) % 60);
  const minutes = Math.floor((durationInMilliseconds / (1000 * 60)) % 60);
  const hours = Math.floor((durationInMilliseconds / (1000 * 60 * 60)));
  return `${hours} ч. ${minutes} мин. ${seconds} сек.`;
}
export default calculateShiftDuration;
