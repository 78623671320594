import apiConfig from '../../../apiConfig.json';

const getShift = async (id, accessToken) => {
  console.log(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.GET_SHIFT}${id}`, accessToken)
  try {
    const response = await fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.GET_SHIFT}${id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    });

    if(!response.ok) {
      console.log(response)
      throw new Error ('Failed to load shift')
    }

    return await response.json();
  } catch (error) {
    console.error(error);
    throw error;
  }
}
export default getShift;
