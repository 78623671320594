const calculateBounds = ([lat, lng, rating]) => {
  const squareSize = 0.001;
  const halfSizeLat = squareSize / 2.8600;

  const lngCorrection = Math.cos(lat * Math.PI /  180);
  const halfSizeLng = (squareSize / 1.1215) * lngCorrection;

  return [
    [lat - halfSizeLat, lng - halfSizeLng],
    [lat + halfSizeLat, lng + halfSizeLng],
  ];
};
export default calculateBounds;
