const checkTokenExpiry = (navigate) => {

  const creationTokenDate = new Date(localStorage.getItem('creationDate'));
  const currentDate = new Date();
  const maxAgeInMilliseconds = 2.5 * 24 * 60 * 60 * 1000;

  if (currentDate - creationTokenDate > maxAgeInMilliseconds) {
    localStorage.removeItem('creationDate');
    localStorage.removeItem('accessToken');
    navigate('/');
    window.location.reload();
  }

};
export default checkTokenExpiry;
