import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AddTaskForm from "./AddTaskForm";
import TasksList from "./TasksList";
import './ganttChart.css'
import TaskForm from "./TaskForm";
import NavigationMenu from "../../Components/NavigationMenu";
import checkTokenExpiry from "../../shared/utils/checkTokenExpiry";

const GanttChart = ({accessToken}) => {

  const [lineHeight, setLineHeight] = useState(0);
  const [chartScrollTop, setChartScrollTop] = useState(0);
  const [isTaskFormOpen, setIsTaskFormOpen] = useState(false);
  const [isTaskFormVisible, setIsTaskFormVisible] = useState(false)
  const colors = ['#C7E1EA', '#FEEDB3', '#FDD4AF', '#A8BEE8', '#CAC7F6', '#DEC1F0'];
  const doneColors = ['#72A4B5', '#FFDF71', '#FEB673', '#7EA0E2', '#9894DC', '#BE89DE'];
  const doneApprovedColors = ['#5690A3', '#F0D164', '#E5A162', '#708EC9', '#716BC5', '#9B69BA'];

  const TASKS = [
    {
        startDate: '7.7.2024',
        endDate: '12.7.2024',
        direction: 'ВНЛ',
        doneApproved: '34%',
        done: '63%'
    },
    {
        startDate: '19.7.2024',
        endDate: '20.7.2024',
        direction: 'ИССО Исакан',
        doneApproved: '34%',
        done: '63%'
    },
    {
        startDate: '1.7.2024',
        endDate: '3.7.2024',
        direction: 'УГР Алонка',
        doneApproved: '34%',
        done: '63%'
    },
    {
        startDate: '14.7.2024',
        endDate: '17.7.2024',
        direction: 'ВНЛ',
        doneApproved: '34%',
        done: '63%'
    },
    {
        startDate: '10.7.2024',
        endDate: '15.7.2024',
        direction: 'ИССО Исакан',
        doneApproved: '34%',
        done: '63%'
    },
    {
        startDate: '9.7.2024',
        endDate: '13.7.2024',
        direction: 'УГР Алонка',
        doneApproved: '34%',
        done: '63%'
    },
    {
        startDate: '3.7.2024',
        endDate: '5.7.2024',
        direction: 'ВНЛ',
        doneApproved: '34%',
        done: '63%'
    },
    {
        startDate: '7.7.2024',
        endDate: '16.7.2024',
        direction: 'ИССО Исакан',
        doneApproved: '34%',
        done: '63%'
    },
    {
        startDate: '2.7.2024',
        endDate: '7.7.2024',
        direction: 'УГР Алонка',
        doneApproved: '34%',
        done: '63%'
    },
    {
        startDate: '17.7.2024',
        endDate: '20.7.2024',
        direction: 'ИССО Исакан',
        doneApproved: '34%',
        done: '63%'
    },
    {
        startDate: '13.7.2024',
        endDate: '15.7.2024',
        direction: 'ВНЛ',
        doneApproved: '34%',
        done: '63%'
    },
    {
        startDate: '1.7.2024',
        endDate: '3.7.2024',
        direction: 'ИССО Исакан',
        doneApproved: '34%',
        done: '63%'
    },
    {
        startDate: '17.7.2024',
        endDate: '20.7.2024',
        direction: 'УГР Алонка',
        doneApproved: '34%',
        done: '63%'
    },
    {
        startDate: '19.7.2024',
        endDate: '20.7.2024',
        direction: 'ВНЛ',
        doneApproved: '34%',
        done: '63%'
    },
    {
        startDate: '1.7.2024',
        endDate: '3.7.2024',
        direction: 'ИССО Исакан',
        doneApproved: '34%',
        done: '63%'
    },
    {
        startDate: '9.7.2024',
        endDate: '13.7.2024',
        direction: 'ВНЛ',
        doneApproved: '34%',
        done: '63%'
    },
    {
        startDate: '3.7.2024',
        endDate: '5.7.2024',
        direction: 'УГР Алонка',
        doneApproved: '34%',
        done: '63%'
    },
    {
        startDate: '7.7.2024',
        endDate: '16.7.2024',
        direction: 'ИССО Исакан',
        doneApproved: '34%',
        done: '63%'
    },
    {
        startDate: '2.7.2024',
        endDate: '7.7.2024',
        direction: 'ИССО Исакан',
        doneApproved: '34%',
        done: '63%'
    },
    {
        startDate: '17.7.2024',
        endDate: '20.7.2024',
        direction: 'ВНЛ',
        doneApproved: '34%',
        done: '63%'
    },
    {
        startDate: '13.7.2024',
        endDate: '15.7.2024',
        direction: 'ИССО Исакан',
        doneApproved: '34%',
        done: '63%'
    }
];

const navigate = useNavigate();

useEffect(() => {
  checkTokenExpiry(navigate);
},[navigate])

console.log(accessToken)

  useEffect(() => {
    const createChart = (e) => {
      const days = document.querySelectorAll(".chart-values li");
      const tasks = document.querySelectorAll(".chart-bars li");
      const daysArray = [...days];
      const chartWrapperHeight = document.querySelector(".chart-bars").clientHeight;
      const tasksCount = tasks.length;
      const minLineHeight = chartWrapperHeight / tasksCount;

      tasks.forEach((el, index) => {
        const duration = el.dataset.duration.split("-");
        const startDay = duration[0];
        const endDay = duration[1];

        const filteredArrayStart = daysArray.filter(day => day.textContent === startDay);
        const filteredArrayEnd = daysArray.filter(day => day.textContent === endDay);

        if (filteredArrayStart.length > 0 && filteredArrayEnd.length > 0) {
          const lineHeight = minLineHeight * (index + 1);
          setLineHeight(lineHeight + 50);

          const left = filteredArrayStart[0].offsetLeft + 1;
          const width = filteredArrayEnd[0].offsetLeft + filteredArrayEnd[0].offsetWidth - left - 42;

        el.style.left = `${left}px`;
        el.style.width = `${width}px`;
        el.style.backgroundColor = el.dataset.color;
        el.style.opacity = 1;

        const progressBar = el.querySelector(".task-progress-bar");
        const progressPercent = parseInt(el.dataset.done) || 0;
        if (progressBar) {
          progressBar.style.width = `${(width * progressPercent) / 100}px`;
          progressBar.style.backgroundColor = el.dataset.donecolor;
        }

        const progressBarApproved = el.querySelector(".task-progress-bar-approved");
        const progressPercentApproved = parseInt(el.dataset.doneapproved) || 0;
        if (progressBarApproved) {
          progressBarApproved.style.width = `${(width * progressPercentApproved) / 100}px`;
          progressBarApproved.style.backgroundColor = el.dataset.doneapprovedcolor;
        }
        } else {
          console.warn(`Не найдены даты: ${startDay} или ${endDay}`);
        }
      });
    };
    createChart();
    window.addEventListener("resize", createChart);
    return () => {
      window.removeEventListener("resize", createChart);
    };
  }, []);

  const handleChartScroll = (e) => {
    setChartScrollTop(e.target.scrollTop);
  };

  const handleButtonClick = () => {
    setIsTaskFormOpen(!isTaskFormOpen);
    setIsTaskFormVisible(false);
  }

  const handleTransitionEnd = () => {
    if (isTaskFormOpen) {
      setIsTaskFormVisible(true);
    }
  };

  return(
    <>
    <div className="body-wrapper">
      <div className="page-wrapper page-flex-wrapper">
        <NavigationMenu currentPage='tracker' accessToken={accessToken}/>
        <div className="gantt-chart-container">
          <div className="list-chart-wrapper">
            <div className="gantt-scroll-x"></div>
            <div className="gantt-scroll-y"></div>
            <div className="chart-wrapper">
              <ul className="chart-values">
                {Array.from({ length: 20 }, (_, i) => {
                  const date = new Date(2024, 6, i + 1);
                  const formattedDate = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
                  return <li key={i}>{formattedDate}</li>;
                })}
              </ul>
              <ul className="chart-bars" onScroll={handleChartScroll}>
                {
                  TASKS.map((task, index) => (
                        <li
                        key={index}
                        data-duration={`${task.startDate}-${task.endDate}`}
                        data-color={colors[index % colors.length]}
                        data-donecolor={doneColors[index % doneColors.length]}
                        data-doneapprovedcolor={doneApprovedColors[index % doneApprovedColors.length]}
                        data-done={task.done}
                        data-doneapproved={task.doneApproved}>
                        <div className="task-progress-bar">
                          <p>{task.done}</p>
                        </div>
                        <div className="task-progress-bar-approved">
                          <p>{task.doneApproved}</p>
                        </div>
                        <section>
                          <p>{task.direction}</p>
                          <button className="button-info-task"></button>
                        </section>
                        </li>
                      ))
                }
              </ul>
            </div>
          </div>
          <Link to='/task-tracker' className='button-close-gantt'></Link>
          <button className={`button-add-task ${isTaskFormOpen ? 'open' : ''}`} onClick={handleButtonClick} onTransitionEnd={handleTransitionEnd}>+</button>
          {
            isTaskFormVisible && (
              <>
                <button className='modal-close-transparent' onClick={handleButtonClick}></button>
                <TaskForm accessToken={accessToken}/>
              </>
            )
          }
        </div>
      </div>
    </div>
      <style>
        {`
          .chart-values li:not(:last-child)::before {
            min-height: ${lineHeight}px;
          }
        `}
      </style>
    </>
  )
}

export default GanttChart;
