import { useState, useEffect, useRef } from 'react';
import ModalDelete from '../../Components/ModalDelete';
import ModalNote from '../../Components/ModalNote';
import './tableRow.css';
import apiConfig from "../../apiConfig.json";
import { createPortal } from 'react-dom';
import UserInfoModal from '../ShiftsTable/UserInfoModal';
import EquipInfoModal from '../ShiftsTable/EquipInfoModal';
import getImageForEquipmentType from '../../shared/functions/getImageForEquipmentType';

const TableRow = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditorDetectorOpen, setIsEditorDetectorOpen] = useState(false);
  const [isEditorPasswordOpen, setIsEditorPasswordOpen] = useState(false);
  const [isEditorEquipOpen, setIsEditorEquipOpen] = useState(false);
  const [isDeleteUserOpen, setIsDeleteUserOpen] = useState(false);
  const [isDeleteEquipOpen, setIsDeleteEquipOpen] = useState(false);
  const [isDeleteDetectorOpen, setIsDeleteDetectorOpen] = useState(false);
  const [isModalNoteOpened, setIsModalNoteOpened] = useState(false);
  const [isModalDeleteOpened, setIsModalDeleteOpened] = useState(false);

  const [username, setUsername] = useState('');
  const [equipVin, setEquipVin] = useState('');
  const [detector, setDetector] = useState('');
  const [newPassword, setNewPassord] = useState('');
  const [equipmentList, setEquipmentList] = useState([]);
  const [originEquipmentList, setOriginEquipmentList] = useState([]);
  const [isEditorDetectorModalActionEnd, setIsEditorDetectorModalActionEnd] = useState(false)
  const [isDetectorDeletionModalActionEnd, setIsDetectorDeletionModalActionEnd] = useState(false)
  const [isPasswordModalActionEnd, setIsPasswordModalActionEnd] = useState(false)
  const [isEquipsModalActionEnd, setIsEquipsModalActionEnd] = useState(false)
  const [isDeleteUserActionEnd, setIsDeleteUserActionEnd] = useState(false)
  const [isDeleteEquipActionEnd, setIsDeleteEquipActionEnd] = useState(false)
  const [newImei, setNewImei] = useState('');
  const [isModalAddImeiOpen, setIsModalAddImeiOpen] = useState(false);
  const [isModalDeleteImeiOpen, setIsModalDeleteImeiOpen] = useState(false);
  const [isEpmtyError, setIsEmptyError] = useState({text: 'Заполните это поле.', state: false});
  const [isError, setError] = useState({text: '', state: false})
  const [isFetchError, setIsFetchError] = useState({text: "", state: false});
  const [showUserInfoModal, setShowUserInfoModal] = useState(false);
  const [showEquipInfoModal, setShowEquipInfoModal] =useState(false);
  const [equip, setEquip] = useState('');
  const [warrantyNum, setWarrantyNum]= useState('');
  const [equipmentType, setEquipmentType] = useState('');

  const modalRef = useRef(null);
  const modalDeleteRef = useRef(null);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const EQUIPS = [
    'EXCAVATOR',
    'BULLDOZER',
    'CRANE',
    'GRADER',
    'RINK',
    'TRUCK',
    'REFUELER',
    'AUTO',
    'POOKER',
    'TRACTOR',
    'MULE',
    'BUS',
    'MIXER'
  ]

  const data = props.data;
  const page = props.page;
  const user = props.user;
  const imei = props.imei;
  const accessToken = props.accessToken;
  const activeColumn = props.activeColumn;
  const mac = data[5]
  const currId = data[6]
  const placeModalElem = document.querySelector('.body-wrapper');
  const onFormSubmit = props.onFormSubmit;

  useEffect(() => {
    const handleCloseFilterByEscape = (e) => {
      if (isModalOpen && e.key === 'Escape') {
        handleCloseModal()
      }
    };
    document.body.addEventListener('keydown', handleCloseFilterByEscape);

    return () => {
      document.body.removeEventListener('keydown', handleCloseFilterByEscape);
    };
  }, [isModalOpen]);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleOpenDetectorEditor = () => setIsEditorDetectorOpen(true);

  const handleCloseNoteModal = (setCurrentAction) => {
    setCurrentAction(false);
    setIsModalNoteOpened(false);
  }

  const handleCloseDeleteModal = () => {
    setIsModalDeleteOpened(false);
  }

  const handleOpenPasswordModal = () => {
    setIsEditorPasswordOpen(true);
    setUsername(data[4]);
  }

  const handleOpenEquipModal = () => {
    setIsEditorEquipOpen(true);
    setUsername(data[4]);
  }

  const handleOpenAddImeiModal = () => {
    setIsModalAddImeiOpen(true);
  }

  const handleOpenDeleteImeiModal = () => {
    setIsModalDeleteImeiOpen(true);
  }

  const handleOpenDeleteUser = () => {
    setIsDeleteUserOpen(true);
    setIsModalDeleteOpened(true);
    setUsername(data[4]);
  }

  const handleOpenDeleteDetector = () => {
    setIsDeleteDetectorOpen(true);
    setIsModalDeleteOpened(true);
  }

  const handleOpenDeleteEquipment = () => {
    setIsDeleteEquipOpen(true);
    setIsModalDeleteOpened(true);
    setEquipVin(data[2]);
  }

  const handleIsEmptyError = (value, setError) => {
    if(!value.length) {
      setError(prevError => ({...prevError, state: true}));
      return true;
    } else {
      setError(prevError => ({...prevError, state: false}));
      return false;
    }
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setIsEditorDetectorOpen(false);
    setIsDeleteDetectorOpen(false)
    setIsEditorPasswordOpen(false);
    setIsEditorEquipOpen(false);
    setIsEditorDetectorModalActionEnd(false);
    setIsDetectorDeletionModalActionEnd(false);
    setIsPasswordModalActionEnd(false);
    setIsEquipsModalActionEnd(false);
    setIsDeleteUserActionEnd(false);
    setIsDeleteEquipActionEnd(false);
    setIsDetectorDeletionModalActionEnd(false)
    setIsModalDeleteOpened(false);
    setIsModalNoteOpened(false);
    setIsModalAddImeiOpen(false);
    setEquipmentList([]);
    setOriginEquipmentList([]);
    setNewPassord('');
    setIsFetchError({text: '', state: false})
    setError(p => ({...p, state: false}))
  }

  useEffect(() => {
    if(isEquipsModalActionEnd || isDeleteEquipActionEnd || isDeleteUserActionEnd || isDetectorDeletionModalActionEnd) {
      const timer = setTimeout(() => {
        setIsModalOpen(false)
        setIsEditorDetectorOpen(false);
        setIsEditorPasswordOpen(false);
        setIsEditorEquipOpen(false);
        setIsModalDeleteOpened(false);
        setIsModalNoteOpened(false);
        setIsDeleteDetectorOpen(false)
        setIsDetectorDeletionModalActionEnd(false);
        setIsDetectorDeletionModalActionEnd(false);
        setIsEquipsModalActionEnd(false);
        setEquipmentList([]);
        setIsFetchError({text: '', state: false})
        setError(p => ({...p, state: false}))
        setNewPassord('');
        handleCloseModal();
        onFormSubmit();
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [isEditorDetectorModalActionEnd, isPasswordModalActionEnd, isEquipsModalActionEnd, isDeleteEquipActionEnd, isDeleteUserActionEnd, isDetectorDeletionModalActionEnd, onFormSubmit]);

  const handleDetectorChange = (e) => {
    if (e.target.value === '') {
      setDetector('');
      return;
    }
    let inputValue = e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, '')
    let maskedValue = '';
    for (let i = 0; i < inputValue.length; i++ ) {
      if(i % 2 === 0 && i !== 0) {
        maskedValue += ':';
      }
      maskedValue +=inputValue[i];
      if(!inputValue) {
        maskedValue = ''
      }

    setDetector(maskedValue);
  }
  }

  const handleSwitchEquipment = (type) => {
    setEquipmentList(prevList => {
      if(!prevList.includes(type)) {
        return [...prevList, type];
      } else {
        return prevList.filter(item => item !== type );
      }
    });
  };

  const handleDeleteUser = () => {
    const name = username;
    fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.DELETE_USER}${name}`, {
      method: "DELETE",
      headers: {
        'Authorization' : `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    })
    .then (response => {
      if(response.ok) {
        console.log('user was deleted successfully!');
        setIsDeleteUserOpen(false);
        handleCloseDeleteModal();
        setIsDeleteUserActionEnd(true);
        setIsModalNoteOpened(true);
        onFormSubmit();
      }
    })
    .catch (error => {
      setIsDeleteUserOpen(false);
      handleCloseDeleteModal();
      setIsDeleteUserActionEnd(true);
      setIsModalNoteOpened(true)
      setIsFetchError({text: error, state: true})
      console.log('error deleting user: ', error);
    });
  }

  const handleDeleteEquipment = () => {
    const vin = equipVin;
    fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.DELETE_EQUIPMENT}${vin}`, {
      method: "DELETE",
      headers: {
        'Authorization' : `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    })
    .then (response => {
      if(response.ok) {
        console.log('equipment was deleted successfully!');
        setIsDeleteEquipOpen(false);
        handleCloseDeleteModal();
        setIsDeleteEquipActionEnd(true);
        setIsModalNoteOpened(true)
      }
    })
    .catch (error => {
      setIsDeleteEquipOpen(false);
      handleCloseDeleteModal();
      setIsDeleteEquipActionEnd(true);
      setIsModalNoteOpened(true)
      setIsFetchError({text: error, state: true});
      console.log('error deleting user: ', error);
    });
  }

  useEffect(() => {
    if (isEditorEquipOpen) {
      setOriginEquipmentList(data[3] || []);
      setEquipmentList(data[3] || []);
    }
  }, [isEditorEquipOpen, data]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmitNewPassword();
      handleSubmitNewDetector();
    }
  };

  const handleSubmitNewPassword = () => {
    if (handleIsEmptyError(newPassword, setIsEmptyError) === true) {
      return;
    };

    if (newPassword.length < 8 ) {
      setError({text: "Длина пароля дожна быть не менее 8 символов", state: true})
      return
    }
    const formData = {
      password: newPassword,
      username: username
    }
    console.log(JSON.stringify(formData))
    
    fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.CHANGE_PASSWORD}`, {
      method: "POST",
      headers: {
        'Authorization' : `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    }).then(response => {
      if(response.ok) {
        console.log('password changed succesfully!');
      }
      setIsEditorPasswordOpen(false)
      setIsPasswordModalActionEnd(true)
      setIsModalNoteOpened(true)
      setIsFetchError({text: "", state: false});
    })
    .catch(error => {
      setIsEditorPasswordOpen(false)
      setIsPasswordModalActionEnd(true)
      setIsModalNoteOpened(true)
      setIsFetchError({text: error, state: true});
      console.log('error changing password: ', error);
    });
  }

  const handleSubmitNewEquips = () => {
    const username = data[4]
    const currentList = equipmentList;
    const originList = originEquipmentList;
    const deletedEquips = originList.filter(item => !currentList.includes(item));
    const addedEquips = currentList.filter(item => !originList.includes(item));
    const urlAdd = `${apiConfig.BASE_URL}.${apiConfig.ENDPOINTS.ADD_EQUIPMENTS}`;
    const urlDelete = `${apiConfig.BASE_URL}.${apiConfig.ENDPOINTS.DELETE_EQUIPMENTS}`
    let formData;

    if(deletedEquips.length && !addedEquips.length) {
      formData = {
        username: username,
        types: deletedEquips
      }
    fetch(urlDelete, {
      method: "POST",
      headers: {
        'Authorization' : `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
    .then(response => {

      if(response.ok) {
        console.log('extra equips were deleted successfully!')
      }
      setIsEditorEquipOpen(false)
      setIsEquipsModalActionEnd(true)
      setIsModalNoteOpened(true);
    })
    .catch(error => {
      setIsEditorEquipOpen(false)
      setIsEquipsModalActionEnd(true)
      setIsModalNoteOpened(true);
      setIsFetchError({text: error, state: true});
      console.log('error fetching new equipment list: ', error);
    })

    } else if (!deletedEquips.length && addedEquips.length) {
      formData = {
        username: username,
        types: addedEquips
      }
      fetch(urlAdd, {
        method: "POST",
        headers: {
          'Authorization' : `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      })
      .then(response => {
        if(response.ok) {
          console.log('new equips were added successfully!')
        }
        setIsEditorEquipOpen(false)
        setIsEquipsModalActionEnd(true)
        setIsModalNoteOpened(true)
      })
      .catch(error => {
        setIsEditorEquipOpen(false)
        setIsEquipsModalActionEnd(true)
        setIsModalNoteOpened(true)
        setIsFetchError({text: error, state: true});
        console.log('error fetching new equipment list: ', error);
      })
    } else if (deletedEquips.length && addedEquips.length) {
      formData = {
        username: username,
        types: deletedEquips
      }
      fetch(urlDelete, {
        method: "POST",
        headers: {
          'Authorization' : `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      })
      .then(response => {
        if(response.ok) {
          console.log('extra equips were deleted successfully!')
        }
        setIsEditorEquipOpen(false)
        setIsEquipsModalActionEnd(true)
        setIsModalNoteOpened(true)
      })
      .catch(error => {
        setIsEditorEquipOpen(false)
        setIsEquipsModalActionEnd(true)
        setIsModalNoteOpened(true)
        setIsFetchError({text: error, state: true});
        console.log('error fetching new equipment list: ', error);
      })

      formData = {
        username: username,
        types: addedEquips
      }
      fetch(urlAdd, {
        method: "POST",
        headers: {
          'Authorization' : `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      })
      .then(response => {
        if(response.ok) {
          console.log('new equips were added successfully!')
        }
        setIsEditorEquipOpen(false)
        setIsEquipsModalActionEnd(true)
        setIsModalNoteOpened(true)
      })
      .catch(error => {
        setIsEditorEquipOpen(false)
        setIsEquipsModalActionEnd(true)
        setIsModalNoteOpened(true)
        setIsFetchError({text: error, state: true});
        console.log('error fetching new equipment list: ', error);
      })
    }
    else {
      return;
    }
  }

  const handleSubmitNewDetector = () => {
    const intId = parseInt(currId)
    const stringDetector = detector.toString()
    fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.EDIT_MAC}?id=${intId}&mac=${stringDetector}`, {
      method: "PUT",
      headers: {
        'Authorization' : `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
  if(response.ok) {
        console.log('detector added succesfully!');
        setIsEditorDetectorOpen(false);
        setIsEditorDetectorModalActionEnd(true);
        setIsModalNoteOpened(true);
        onFormSubmit();
      }
    })
    .catch(error => {
      setIsEditorDetectorOpen(false);
        setIsEditorDetectorModalActionEnd(true);
        setIsModalNoteOpened(true);
      setIsFetchError({text: error, state: true})
      console.log('adding detector error: ', error);
    });
  }

  const handleSubmiteDeleteDetector = () => {
    const intId = parseInt(currId)
     fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.EDIT_MAC}?id=${intId}&mac=${null}`, {
      method: "PUT",
      headers: {
        'Authorization' : `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
     })
     .then(response => {
      if(response.ok) {
        console.log('detector deleted succesfully!');
        setIsDeleteDetectorOpen(false);
        setIsDetectorDeletionModalActionEnd(true);
        setIsModalNoteOpened(true);
        onFormSubmit();
       }
     })
     .catch(error => {
       setIsDeleteDetectorOpen(false);
         setIsDetectorDeletionModalActionEnd(true);
         setIsModalNoteOpened(true);
       setIsFetchError({text: error, state: true})
       console.log('error deleting detector: ', error);
     });
  }

const handleAddImei = () => {

}

const handleDeleteImei = () => {

}

const toggleUserInfoModal = (user) => {
  setShowUserInfoModal(!showUserInfoModal);
}

const toggleEquipInfoModal = (equip, warrantyNum, equipmentType) => {
  setShowEquipInfoModal(!showEquipInfoModal);
  setEquip(equip);
  setWarrantyNum(warrantyNum);
  setEquipmentType(equipmentType);
}

  return(
    <>
    <UserInfoModal show={showUserInfoModal} onClose={toggleUserInfoModal} userName={user} handleCloseActionModal={handleCloseModal} accessToken={accessToken}/>
    <EquipInfoModal show={showEquipInfoModal} onClose={toggleEquipInfoModal} equip={equip} warrantyNum={warrantyNum} equipmentType={equipmentType} handleCloseActionModal={handleCloseModal} accessToken={accessToken}/>
      {
        page === 'users' ? (
          <>
            <div className='table-row table-row-users'>
              <ul className="table-row-list">
                <li className={isMobile ? 'table-row-item table-row-item-mobile' : "table-row-item"}>
                  {
                    imei !== null ? (
                      <div className="table-column-warranty table-row-item__imei-exists">
                        <button className="table-row-item__button--user" onClick={() => toggleUserInfoModal()}>
                          <p>{data[0]}</p>
                        </button>
                      </div>
                    ) : (
                      <div className="table-column-warranty">
                        <button className="table-row-item__button--user" onClick={() => toggleUserInfoModal()}>
                          <p>{data[0]}</p>
                        </button>
                      </div>
                    )
                  }
                </li>
                <li className={isMobile ? activeColumn === 0 ? 'table-row-item table-row-item-mobile' : 'inactive' : "table-row-item"}>
                  <p>{data[1]}</p>
                </li>
                <li className={isMobile ? activeColumn === 1 ? 'table-row-item table-row-item-mobile' : 'inactive' : "table-row-item"}>
                  <p>{data[2]}</p>
                </li>
                <li className={isMobile ? activeColumn === 2 ? 'table-row-item table-row-item-mobile' : 'inactive' : "table-row-item"}>
                  <ul className='users-equip-list'>
                      {
                        data[3].map((equip, index) => {
                          return(
                            // <>
                              <li key={index} className='users-equip-item'>
                                <img src={getImageForEquipmentType(equip)} alt="warrantyNum" width={30} height={30}/>
                              </li>
                            // </>
                          )
                        })
                      }
                  </ul>
                </li>
              </ul>
              <button className="table-row__button addition-change__button" onClick={handleOpenModal} />

                  {isModalOpen &&
                    <>
                      <div className="addition-change-modal" ref={modalRef}>
                          <button className="addition-close-modal__button" onClick={handleCloseModal}/>

                        {isEditorPasswordOpen ? (
                          <>
                          <label className="addition-label">
                          <input
                            type="text"
                            className="addition-modal__input new-password__input"
                            value={newPassword}
                            onChange={(e) => {setNewPassord(e.target.value); handleIsEmptyError(e.target.value, setIsEmptyError)}}
                            onKeyDown={handleKeyPress}
                            minLength={8}
                            placeholder='Введите новый пароль' />
                            {isError.state &&
                            <div className="form__error-message">{isError.text}</div> //выводится, если длина пароля менее 8 символов
                          }
                          {(isEpmtyError.state) &&
                            <p className="validation-error-message">{isEpmtyError.text}</p>
                          }
                          <button className="addition-modal__button addition-users-add-password" onClick={handleSubmitNewPassword}>
                            Добавить
                          </button>
                          </label>
                          </>
                        ) : (isPasswordModalActionEnd && isModalNoteOpened) ? (
                          !isFetchError.state ? (
                            createPortal (
                              <ModalNote
                              note="Пароль успешно изменён! Можете скопировать свой новый пароль:"
                              type="success"
                              onClose={handleCloseModal}
                              password={newPassword} />,
                              placeModalElem
                            )
                            ) : (
                              createPortal (
                                <ModalNote
                                note= {`Возникла ошибка при замене пароля: ${isFetchError.text}`}
                                type="error"
                                onClose={handleCloseModal}
                                />,
                                placeModalElem
                              )
                            )
                        ) : isEditorEquipOpen ? (
                          <>
                          <label className="addition-label modal-change-equip">
                            {(isEpmtyError.state) &&
                              <p className="validation-error-message addition-form__role__error-message">{isEpmtyError.text}</p>
                            }
                            {EQUIPS.map((equip, index) => {
                              return (
                                <button key={index} type='button' className={`addition-button-add-equipment modal-change-equipment technic-type-${equipmentList.includes(equip) ? 'white' : 'black'}-${equip.toLowerCase()} ${equipmentList.includes(equip) ? 'selected' : ''}`}
                                onClick={(e) => {handleSwitchEquipment(e.target.value); }}
                                value={equip}
                                />
                              )
                            })}
                            <button className="addition-modal__button addition-users-add-password" onClick={handleSubmitNewEquips}>
                              Изменить
                            </button>
                          </label>
                          </>
                        ) : (isEquipsModalActionEnd  && isModalNoteOpened) ? (
                          !isFetchError.state ? (
                            createPortal (
                              <ModalNote
                                note="Данные об оборудовании успешно сменены!"
                                type="success"
                                onClose={() => handleCloseNoteModal(setIsEquipsModalActionEnd)}
                              />,
                              placeModalElem
                            )

                          ) : (
                            createPortal (
                              <ModalNote
                              note={`Ошибка при смене оборудования: ${isFetchError.text}`}
                              type="error"
                              onClose={() => handleCloseNoteModal(setIsEquipsModalActionEnd)}
                              />,
                              placeModalElem
                            )
                          )
                        )
                        : (isDeleteUserOpen && isModalDeleteOpened) ? (
                          createPortal (
                            <ModalDelete
                            modalRef={modalDeleteRef}
                            message='Вы уверены, что хотите удалить пользователя? Отменить действие будет невозможно.'
                            actionText='Да, удалить'
                            action={handleDeleteUser}
                            onClose={handleCloseDeleteModal}/>,
                            placeModalElem
                          )
                        )
                        : (isDeleteUserActionEnd && isModalNoteOpened) ? (
                            !isFetchError.state ? (
                              createPortal (
                                <ModalNote
                                note="Пользователь успешно удалён!"
                                type="success"
                                onClose={() => handleCloseNoteModal(setIsDeleteUserActionEnd)}
                                />,
                                placeModalElem
                              )

                            ) : (
                              createPortal (
                                <ModalNote
                                note={`Ошибка при удалении пользователя: ${isFetchError.text}`}
                                type="error"
                                onClose={() => handleCloseNoteModal(setIsDeleteUserActionEnd)}
                                />,
                                placeModalElem
                              )
                            )
                        )
                        : (isModalAddImeiOpen) ? (
                            <>
                            <label className="addition-label">
                          {(isEpmtyError.state) &&
                            <p className="validation-error-message">{isEpmtyError.text}</p>
                          }
                          <input
                            type="text"
                            className="addition-modal__input"
                            value={newImei}
                            onChange={(e) => {setNewImei(e.target.value); handleIsEmptyError(e.target.value, setIsEmptyError)}}
                            onKeyDown={handleKeyPress}
                            placeholder='Введите imei' />
                          <button className="addition-modal__button addition-users-add-password" onClick={handleAddImei}>
                            Добавить
                          </button>
                          </label>
                            </>
                        )
                        : (isModalDeleteImeiOpen && isModalDeleteOpened) ? (
                            <>
                            createPortal (
                                <ModalDelete
                                message='Вы уверены, что хотите удалить imei? Отменить действие будет невозможно.'
                                actionText='Да, удалить'
                                action={handleDeleteImei}
                                onClose={handleCloseDeleteModal}/>,
                                placeModalElem
                              )
                            </>
                        )
                        : (
                          <>
                            <button className="users-modal-option" onClick={handleOpenPasswordModal}>
                              Изменить пароль
                            </button>
                            <button className="users-modal-option" onClick={handleOpenEquipModal}>
                              Изменить оборудование
                            </button>
                            {imei ? (
                            <button className="users-modal-option" onClick={handleOpenDeleteImeiModal}>
                            Удалить imei
                          </button>
                            ) : (
                            <button className="users-modal-option" onClick={handleOpenAddImeiModal}>
                              Добавить imei
                            </button>
                            )}
                            <button className="users-modal-option users-modal-option-delete-user" onClick={handleOpenDeleteUser}>
                              Удалить пользователя
                            </button>
                          </>
                        )

                      }
                    </div>
                  </>
                }
            </div>
          </>
        ) : (
          <>
            <div className='table-row'>
                <ul className="table-row-list">
                  <li className={isMobile ? 'table-row-item table-row-item-mobile' : "table-row-item"}>
                    <div className="table-column-warranty">
                      <button className='table-column-warranty__button' onClick={() => toggleEquipInfoModal(data[2], data[1], data[0])}>
                        <img src={getImageForEquipmentType(data[0])} alt="warrantyNum" width={30} height={30}/>
                        <p>{data[1]}</p>
                      </button>
                    </div>
                  </li>
                  <li className={isMobile ? activeColumn === 0 ? 'table-row-item table-row-item-mobile' : 'inactive' : "table-row-item"}>
                    <p>{data[2]}</p>
                  </li>
                  <li className={isMobile ? activeColumn === 1 ? 'table-row-item table-row-item-mobile' : 'inactive' : "table-row-item"}>
                    <p>{data[3]}</p>
                  </li>
                  <li className={isMobile ? activeColumn === 2 ? 'table-row-item table-row-item-mobile' : 'inactive' : "table-row-item"}>
                    <p>{data[4]}</p>
                  </li>
                </ul>
                <button className="table-row__button addition-change__button" onClick={handleOpenModal} />

                {isModalOpen &&
                  <div className="addition-equip-modal">
                    <div className="addition-change-modal" ref={modalRef}>
                      <button className="addition-close-modal__button" onClick={handleCloseModal}/>
                      {isEditorDetectorOpen ? (
                          <>
                          <label className="addition-label">
                            {(isEpmtyError.state) &&
                              <p className="validation-error-message">{isEpmtyError.text}</p>
                            }
                            <input type="text" className="addition-modal__input new-detector__input"
                              value={detector}
                              onChange={(e) => {handleDetectorChange(e); handleIsEmptyError(e.target.value, setIsEmptyError)}}
                              onKeyDown={handleKeyPress}
                              placeholder='__:__:__:__:__'
                              />
                            <button className="addition-modal__button addition-detector-add-detector" onClick={handleSubmitNewDetector}>
                              Добавить
                            </button>
                          </label>
                          </>
                          )
                          : (isEditorDetectorModalActionEnd && isModalNoteOpened) ?
                          ( !isFetchError.state ? (
                            createPortal (
                              <ModalNote
                                type="success"
                                note="Датчик успешно добавлен! Можете его скопировать:"
                                onClose={() => handleCloseNoteModal(setIsEditorDetectorModalActionEnd)}
                                detector={detector}
                              />, placeModalElem
                            )
                          ) : (
                            createPortal (
                              <ModalNote
                                type="error"
                                note={`Произошла ошибка при добавлении датчика: ${isFetchError.text}`}
                                onClose={() => handleCloseNoteModal(setIsEditorDetectorModalActionEnd)}
                              />, placeModalElem
                            )
                          )
                          )
                          : (isDetectorDeletionModalActionEnd && isModalNoteOpened) ?
                          ( !isFetchError.state ? (
                            createPortal (
                              <ModalNote
                                type="success"
                                note="Датчик успешно удалён!"
                                onClose={() => handleCloseNoteModal(setIsDetectorDeletionModalActionEnd)}
                              />, placeModalElem
                            )
                          ) : (
                            createPortal (
                              <ModalNote
                                type="error"
                                note={`Произошла ошибка при удалении датчика: ${isFetchError.text}`}
                                onClose={() => handleCloseNoteModal(setIsDetectorDeletionModalActionEnd)}
                              />, placeModalElem
                            )
                          )
                            // <div className="detector-editor-note__wrapper">
                            //   <p className="detector-editor-note">Датчик успешно добавлен!</p>
                            // </div>
                          )
                          : (isDeleteDetectorOpen && isModalDeleteOpened) ? (
                            createPortal (
                              <ModalDelete
                              modalRef={modalDeleteRef}
                              message='Вы уверены, что хотите удалить датчик? Отменить действие будет невозможно.'
                              actionText='Да, удалить'
                              action={handleSubmiteDeleteDetector}
                              onClose={handleCloseDeleteModal}/>,
                              placeModalElem
                            )
                          )
                          : (isDeleteEquipOpen && isModalDeleteOpened) ? (
                            createPortal (
                              <ModalDelete
                              modalRef={modalDeleteRef}
                              message='Вы уверены, что хотите удалить оборудование? Отменить действие будет невозможно.'
                              actionText='Да, удалить'
                              action={handleDeleteEquipment}
                              onClose={handleCloseDeleteModal}/>,
                              placeModalElem
                            )
                              //  <p className="modal-delete-note">Вы уверены, что хотите удалить оборудование? Отменить действие будет невозможно.</p>
                              // <div className="modal-delete__buttons-wrapper">
                              // <button className="modal-delete__button modal-delete__button-disgard" onClick={() => {handleOpenModal(); setIsDeleteEquipOpen(false)}}>
                              //   Нет, не удалять
                              // </button>
                              // <button className="modal-delete__button modal-delete__button-accept" onClick={handleDeleteEquipment}>
                              //   Да, удалить
                              // </button>
                              // </div>
                          )
                          : (isDeleteEquipActionEnd && isModalNoteOpened) ? (
                            !isFetchError.state ? (
                              createPortal (
                                <ModalNote
                                  type="success"
                                  note="Оборудование успешно удалено!"
                                  onClose={() => handleCloseNoteModal(setIsDeleteEquipActionEnd)}

                                />, placeModalElem
                              )
                            ) : (
                              createPortal (
                                <ModalNote
                                  note={`Ошибка при удалении оборудования: ${isFetchError.text}`}
                                  type="error"
                                  onClose={() => handleCloseNoteModal(setIsDeleteEquipActionEnd)}
                                  />,
                                  placeModalElem
                                )
                            )
                            // <div className="detector-editor-note__wrapper">
                            //   <p className="detector-editor-note">Оборудование успешно удалено!</p>
                            // </div>
                          )
                          : (
                            <>
                            {
                              mac === '-' ? (
                                <button className="users-modal-option" onClick={handleOpenDetectorEditor}>
                                Добавить датчик
                                </button>
                              ) : (
                                <button className="users-modal-option" onClick={handleOpenDeleteDetector}>Удалить датчик</button>
                              )
                            }
                            <button className="users-modal-option users-modal-option-delete-user" onClick={handleOpenDeleteEquipment}>
                              Удалить оборудование
                            </button>
                          </>
                          )
                        }
                    </div>
                  </div>
                }

              </div>
          </>
        )
      }
    </>
  )
}
export default TableRow;
